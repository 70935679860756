<template>
  <DashboardLayout>
    <div class="flex flex-col h-screen">
      <div class="flex-1 flex flex-col">
        <div class="flex-1 overflow-y-auto">
          <div class="container max-w-3xl mx-auto p-4 bg-admin-100 text-black rounded-lg shadow-md mt-28">
            <div class="flex items-center justify-center">
              <h2 class="text-2xl font-bold text-orange-500">Deposit Postbacks</h2>
            </div>
            <div class="mt-12">
              <div class="flex flex-col bg-gray-200 rounded-lg overflow-hidden">
                <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
                  <div class="py-3 px-4 font-semibold flex-1">ID</div>
                  <div v-show="this.role === this.$defaultUsergroups.superadmin" class="py-3 px-4 font-semibold flex-1">Click ID</div>
                  <div class="py-3 px-4 font-semibold flex-1">User ID</div>
                  <div class="py-3 px-4 font-semibold flex-1">Username</div>
                  <div class="py-3 px-4 font-semibold flex-1">Send at</div>
                </div>
                <div v-for="postback in paginatedPostbacks" :key="postback.id" class="flex border-b border-gray-400 hover:bg-gray-300 transition-colors duration-200">
                  <div class="py-3 px-4 flex-1">{{ postback.id }}</div>
                  <div v-show="this.role === this.$defaultUsergroups.superadmin" class="py-3 px-4 flex-1">{{ postback.click_id }}</div>
                  <div class="py-3 px-4 flex-1">{{ postback.bid}}</div>
                  <div class="py-3 px-4 flex-1">{{ postback.amount}}</div>
                  <div class="py-3 px-4 flex-1">{{ postback.created_at }}</div>
                </div>
              </div>
              <PaginationComponent
                  v-show="this.totalPages > 1"
                  :currentPage="currentPage"
                  :totalPages="totalPages"
                  @update:currentPage="currentPage = $event"
              />
            </div>
          </div>
        </div>
      </div>


    </div>
  </DashboardLayout>
 </template>


<script>
import DashboardLayout from "@/views/DashboardLayout.vue";
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";
import SearchComponent from "@/components/Fields/SearchComponent.vue";
import ConfirmationModalComponent from "@/components/Fields/ConfirmationModalComponent.vue";

export default {
  components: {
    ConfirmationModalComponent, SearchComponent, PaginationComponent,
    DashboardLayout
  },
  data() {
    return {
      postbacks: [],
      currentPage: 1,
      recordsPerPage: 5
    };
  },
  methods:{
    async fetchDeposits(){
      let response = await this.$axios.request({
        url: '/postback/',
        method: 'get'
      });
      if(response.data.status === 'success'){
        this.postbacks = response.data.deposit_postbacks;
      }
    }
  },
  mounted(){
    this.fetchDeposits();
  },
  computed: {
    totalPages(){
      if(this.postbacks.length === 0) return 1;
      return Math.ceil(this.postbacks.length / this.recordsPerPage);
    },
    paginatedPostbacks(){
      let start = (this.currentPage - 1) * this.recordsPerPage;
      let end = start + this.recordsPerPage;
      return this.postbacks.slice(start, end);
    }
  }
};
</script>
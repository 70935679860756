<template>
  <DashboardLayout>
    <div class="flex justify-center items-center bg-gradient-white mt-6">
      <div class="container max-w-lg bg-[#eeeeee] text-black p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">User List</h2>
        <div class="mb-4 flex justify-between items-center gap-4">
          <input
              type="text"
              :placeholder="$translate('search.by_name')"
              v-model="searchQuery"
              class="p-2 rounded bg-gradient-white text-black placeholder-gray-600 focus:outline-none focus:ring-2 focus:ring-orange-500"
          />
        </div>
        <div class="flex flex-col">
          <div class="flex bg-orange-500 text-white p-3 rounded-t-lg">
            <div class="py-3 font-bold px-4 w-1/4 text-center">{{ ($translate('users.manage_users.id')) }}</div>
            <div class="py-3 font-bold px-4 w-1/4 text-center">{{ ($translate('users.manage_users.full_name')) }}</div>
            <div class="py-3 font-bold px-4 w-1/4 text-center">{{ ($translate('email')) }}</div>
<!--            <div class="py-3 font-bold px-4 w-1/4 text-center">Status</div>-->
            <div class="py-3 font-bold px-4 w-1/4 text-center">{{ ($translate('userGroup')) }}</div>
            <div class="py-3 font-bold px-4 w-1/4 text-center">{{ ($translate('action')) }}</div>
          </div>
<!--          <div v-for="user in userList" :key="user.id" class="flex items-center border-b border-gray-400 hover:bg-gray-300 transition-colors duration-200">-->
          <div v-for="user in paginatedUsers" :key="user.id" class="flex items-center border-b border-gray-400 hover:bg-gray-300 transition-colors duration-200">
            <div class="py-3 px-4 w-1/5 text-center">{{ user.id }}</div>
            <div class="py-3 px-4 w-1/5 text-center">{{ user.first_name }} {{ user.last_name }}</div>
            <div class="py-3 px-4 w-1/5 text-center">{{ user.email }}</div>
<!--            <div-->
<!--                :class="{-->
<!--                'text-green-600': user.status_id === 1,-->
<!--                'text-red-600': user.status_id === 3,-->
<!--                'text-yellow-600': user.status_id === 0-->
<!--              }"-->
<!--                class="py-3 px-4 w-1/5 text-center"-->
<!--            >-->
<!--              {{ user.status_id === 1 ? 'Active' : user.status_id === 3 ? 'Blocked' : 'Pending' }}-->
<!--            </div>-->
            <div
                class="py-3 px-4 w-1/5 text-center">
              {{ userGroups[user.usergroup_id] || 'Unknown Group' }}
            </div>
            <div class="flex justify-center gap-3 w-1/5 py-3">
              <button
                  class="w-1/2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none"
                  @click="viewUser(user)">
                <span class="material-icons">visibility</span>
              </button>
            </div>
          </div>
        </div>


        <PaginationComponent
            :currentPage="currentPage"
            :totalPages="totalPages"
            @update:currentPage="currentPage = $event"
        />

<!--        <div class="flex justify-center items-center mt-4">-->
<!--          <button-->
<!--              class="px-4 py-2 mx-1 bg-orange-500 text-white rounded hover:bg-orange-600"-->
<!--              :disabled="currentPage === 1"-->
<!--              @click="currentPage&#45;&#45;"-->
<!--          >-->
<!--            Previous-->
<!--          </button>-->
<!--          <span class="mx-2">{{ currentPage }} / {{ totalPages }}</span>-->
<!--          <button-->
<!--              class="px-4 py-2 mx-1 bg-orange-500 text-white rounded hover:bg-orange-600"-->
<!--              :disabled="currentPage === totalPages"-->
<!--              @click="currentPage++"-->
<!--          >-->
<!--            Next-->
<!--          </button>-->
<!--        </div>-->



        <transition name="fade">
          <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div class="bg-white p-8 rounded-lg shadow-lg w-xl max-w-4xl mx-4 transform transition-all duration-300 ease-in-out scale-95 hover:scale-100">
              <h3 class="text-2xl font-semibold text-gray-800 mb-4">{{ ($translate('users.manage_users.user_details')) }}</h3>
              <div class="flex border-b mb-4">
                <button
                    v-for="(tab, index) in tabs"
                    :key="index"
                    @click="currentTab = index"
                    class="px-4 py-2 text-lg font-semibold focus:outline-none"
                    :class="{'border-b-2 border-orange-500': currentTab === index}">
                  {{ tab }}
                </button>
              </div>
              <div v-show="currentTab === 0" class="mt-4">
                <div class="mt-4">
                  <label for="id" class="block">{{ ($translate('users.manage_users.id')) }}</label>
                  <input
                      type="text"
                      id="id"
                      v-model="selectedUser.id"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                      disabled
                  />
                </div>
                <div class="mt-4">
                  <label for="email" class="block">{{ ($translate('email')) }}</label>
                  <input
                      type="email"
                      id="email"
                      v-model="selectedUser.email"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                </div>
                <div class="mt-4">
                  <label for="change_password" class="block">{{ ($translate('users.manage_users.change_password')) }}</label>
                  <input
                      type="password"
                      id="change_password"
                      v-model="selectedUser.change_password"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                </div>
                <div class="mt-4">
                  <label for="email_verified" class="block mb-2">{{ ($translate('users.manage_users.email_verified')) }}</label>
                  <ToggleSwitchComponent
                      v-model="selectedUser.email_verified"
                      id="email_verified"
                      class="mr-2"
                  />
                  Yes
                </div>
                <div class="mt-4">
                  <label for="status_id" class="block">Status:</label>
                  <select
                      id="status_id"
                      v-model="selectedUser.status_id"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md">
                    <option :value="1">Active</option>
                    <option :value="3">Blocked</option>
                    <option :value="0">Pending</option>
                  </select>
                </div>
                <div class="mt-4">
                  <label for="usergroup_id" class="block">{{ ($translate('userGroup')) }}</label>
                  <select
                      id="usergroup_id"
                      v-model="selectedUser.usergroup_id"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md">
                    <option v-for="(name, id) in userGroups" :key="id" :value="id">{{ name }}</option>
                  </select>
                </div>
                <div class="mt-4">
                  <label for="created_at" class="block">{{ ($translate('created_at')) }}</label>
                  <input
                      type="text"
                      id="created_at"
                      v-model="selectedUser.created_at"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                      disabled
                  />
                </div>
              </div>
              <div v-show="currentTab === 1" class="mt-4">
                <div class="mt-4">
                  <label for="company_name" class="block">{{ ($translate('companyName')) }}</label>
                  <input
                      type="text"
                      id="company_name"
                      v-model="selectedUser.company_name"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                </div>
                <div class="mt-4">
                  <label for="first_name" class="block">{{ ($translate('firstName')) }}</label>
                  <input
                      type="text"
                      id="first_name"
                      v-model="selectedUser.first_name"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                </div>
                <div class="mt-4">
                  <label for="last_name" class="block">{{ ($translate('lastName')) }}</label>
                  <input
                      type="text"
                      id="last_name"
                      v-model="selectedUser.last_name"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                </div>
                <div class="mt-4">
                  <label for="address" class="block">{{ ($translate('address')) }}</label>
                  <input
                      type="text"
                      id="address"
                      v-model="selectedUser.address"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                </div>
                <div class="mt-4">
                  <label for="city" class="block">{{ ($translate('city')) }}</label>
                  <input
                      type="text"
                      id="city"
                      v-model="selectedUser.city"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                </div>
                <div class="mt-4">
                  <label for="postal_code" class="block">{{ ($translate('postalCode')) }}</label>
                  <input
                      type="text"
                      id="postal_code"
                      v-model="selectedUser.postal_code"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                </div>
                <div class="mt-4">
                  <label for="city" class="block">{{ ($translate('country')) }}</label>
                  <input
                      type="text"
                      id="country"
                      v-model="selectedUser.country"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div v-show="currentTab === 2" class="mt-4">
                <div class="mt-4">
                  <label for="bank_name" class="block">{{ ($translate('bank_name')) }}</label>
                  <input
                      type="text"
                      id="bank_name"
                      v-model="selectedUser.payment_details.bank_name"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                </div>
                <div class="mt-4">
                  <label for="iban" class="block">{{ ($translate('payment.iban')) }}</label>
                  <input
                      type="text"
                      id="iban"
                      v-model="selectedUser.payment_details.iban"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                </div>
                <div class="mt-4">
                  <label for="bic" class="block">{{ ($translate('payment.swift')) }}</label>
                  <input
                      type="text"
                      id="bic"
                      v-model="selectedUser.payment_details.swift_code"
                      class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div class="mt-4 flex justify-end gap-4">
                <button
                    class="px-4 py-2 bg-admin-200 text-white rounded-md hover:bg-admin-200"
                    @click="saveChanges">{{ ($translate('save')) }}</button>
                <button
                    class="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
                    @click="closeModal">{{ ($translate('close')) }}</button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import ToggleSwitchComponent from "@/components/Fields/ToggleSwitchComponent.vue";
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";
export default {
  components: {PaginationComponent, ToggleSwitchComponent, DashboardLayout, ButtonComponent },
  data() {
    return {
      isModalOpen: false,
      selectedUser: {},
      userGroups: {},
      currentTab: 0,
      tabs: [this.$translate('users.manage_users.accountInfoTab'), this.$translate('users.manage_users.basicInfoTab'), this.$translate('users.manage_users.paymentDetailsTab')],
      userList: [],
      searchQuery: '',
      currentPage: 1,
      recordsPerPage: 5,
    };
  },
  methods: {
    async getUsergroups() {
      try {
        const response = await this.$axios.request({
          method: 'GET',
          url: '/usergroup/id'
        });
        this.userGroups = response.data.user_groups.reduce((acc, group) => {
          acc[group.id] = group.name;
          return acc;
        }, {});

      } catch (error) {
        console.error("Failed to fetch usergroups:", error);
      }
    },
    viewUser(user) {
      this.selectedUser = user;
      this.selectedUser.email_verified = !!user.email_verified;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    async fetchUsers() {
      try {
        const response = await this.$axios.request(
            {
              method: "GET",
              url: "/users",
            }
        );
        this.userList = response.data;
        this.userList.sort((a, b) => b.id - a.id);


        if(this.userList)
        if(this.userList.error) localStorage.removeItem('token');
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    },
    async saveChanges() {
      try {
        const updatedUser = { ...this.selectedUser };
          const response = await this.$axios.request({
              url: `/users/index/${this.selectedUser.id}`,
              method: 'PUT',
              data: updatedUser
          });

        if (response.status === 200) {
          this.isModalOpen = false;
          const updatedUserIndex = this.userList.findIndex(user => user.id === this.selectedUser.id);
          if (updatedUserIndex !== -1) {
            this.userList[updatedUserIndex] = updatedUser;
          }

          this.$toast.success('User details updated successfully!');
        }
      } catch (error) {
        console.error("Failed to save user changes:", error);
        // Optionally show an error message
        this.$toast.error('Failed to update user details. Please try again later.');
      }
    },
  },
  mounted(){
    this.fetchUsers();
    this.getUsergroups();
  },
  computed:{
    filteredUsers() {
      if (this.userList) {
        return this.userList.filter(user => {
          const firstName = user.first_name?.toLowerCase() || '';
          const lastName = user.last_name?.toLowerCase() || '';
          const query = this.searchQuery?.toLowerCase() || '';
          return firstName.includes(query) || lastName.includes(query);
        });
      }
      return [];
    },
    totalPages() {
      if(this.filteredUsers.length === 0) return 1;
      return Math.ceil(this.filteredUsers.length / this.recordsPerPage);
    },
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.recordsPerPage;
      const end = start + this.recordsPerPage;
      return this.filteredUsers.slice(start, end);
    },
  }
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
  overflow-y: auto;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
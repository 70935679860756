<template>
  <header class="flex justify-between items-center p-4 bg-gray-800 shadow h-[5rem]">
    <div class="flex-1">
      <div class="relative">
<!--        <input-->
<!--            class="w-full max-w-sm p-2 rounded block px-3 py-2 bg-gray-700 border border-gray-600  shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"-->
<!--            type="text"-->
<!--            placeholder="Search..."-->
<!--            v-model="searchQuery"-->
<!--            @input="onSearchInput"-->
<!--            />-->
        <!-- Dropdown for search results -->
<!--        <div v-if="showSearchResults && searchQuery" class="absolute left-0 right-0 mt-2 w-full bg-white border border-gray-300 rounded shadow-lg z-10">-->
<!--          <ul class="divide-y divide-gray-200">-->
<!--            <li v-for="(item, index) in filteredResults" :key="index" class="px-4 py-2 cursor-pointer hover:bg-gray-100">-->
<!--              {{ item.text }}-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->

      </div>
    </div>
    <div class="flex items-center space-x-4">


      <!-- <button @click="onNotificationsClick" class="focus:outline-none relative">
        <span class="material-icons mt-2 text-orange-700">notifications</span>
      
        <span v-if="unreadNotifications > 0" class="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full"></span>
       
        <div v-if="showNotifications"
             class="absolute right-0 mt-2 w-64 bg-white border border-gray-300 rounded shadow-lg z-10">
          <ul class="divide-y divide-gray-200">
            <li v-for="(notification, index) in notifications" :key="index" @click="markNotificationAsRead(index)"
                class="px-4 py-2 cursor-pointer hover:bg-gray-100">
              {{ notification.text }}
              <span v-if="!notification.isRead" class="text-xs text-gray-500"> (Unread)</span>
            </li>
          </ul>
     
          <div class="px-4 py-2">
            <button @click="goToNotifications"
                    class="w-full text-center text-admin-200 hover:border-admin-200 hover:bg-gray-100 py-2 px-4 border border-transparent rounded-md transition-colors duration-200 ease-in-out">
              Show All
            </button>
        </div>
        </div>
      </button> -->

      <!-- Messages Button with Dropdown -->

      
      <!-- <button @click="onMessagesClick" class="focus:outline-none relative">
        <span class="material-icons mt-2 ml-6 mr-2 text-orange-700">message</span>
       
        <span v-if="unreadMessages > 0" class="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full"></span>
       
        <div v-if="showMessages"
             class="absolute right-0 mt-2 w-64 bg-white border border-gray-300 rounded shadow-lg z-10">
          <ul class="divide-y divide-gray-200">
            <li v-for="(message, index) in messages" :key="index" @click="markMessageAsRead(index)"
                class="px-4 py-2 cursor-pointer hover:bg-gray-100">
              {{ message.text }}
              <span v-if="!message.isRead" class="text-xs text-gray-500"> (Unread)</span>
            </li>
          </ul>

          <div class="px-4 py-2">
            <button @click="goToInbox"
                    class="w-full text-center text-admin-200 hover:border-admin-200 hover:bg-gray-100 py-2 px-4 border border-transparent rounded-md transition-colors duration-200 ease-in-out">
              Go to Inbox
            </button>
          </div>
        </div>
      </button> -->

      <!-- User Avatar with Dropdown Menu -->
<!--      <div class="relative flex items-center">-->
<!--        <img :src="user.avatar" alt="User Avatar" class="w-10 h-10 rounded-full mr-2 cursor-pointer" @click="toggleProfileMenu"/>-->
<!--        <span class="text-orange-700">Hi, {{ user.name }}</span>-->
<!--        &lt;!&ndash; Profile Dropdown Menu &ndash;&gt;-->
<!--        <div v-if="showProfileMenu"-->
<!--             class="absolute right-0 mt-48 w-48 bg-white border border-gray-300 rounded shadow-lg z-10">-->
<!--          <ul class="divide-y divide-gray-200">-->
<!--            <li @click="goToProfile" class="px-4 py-2 cursor-pointer hover:bg-gray-100">-->
<!--              View Profile-->
<!--            </li>-->
<!--            <li @click="logout" class="px-4 py-2 cursor-pointer hover:bg-gray-100">-->
<!--              Logout-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </header>
</template>

<script>
import InputField from '@/components/Form/InputFieldComponent.vue';
import LanguageSelector from "@/components/Fields/LanguageSelector.vue";
export default {
  components: {
    InputField, // Register the InputField component
    LanguageSelector, // Register the LanguageSelector component
  },
  data() {
    return {
      searchQuery: '',
      user: {
        name: 'John Doe',
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4YreOWfDX3kK-QLAbAL4ufCPc84ol2MA8Xg&s', // Replace with actual user avatar URL
      },
      notifications: [
        {text: 'You have a new follower', isRead: false},
        {text: 'New comment on your post', isRead: false},
        {text: 'Your profile was viewed', isRead: true},
      ],
      messages: [
        {text: 'Hey! How are you?', isRead: false},
        {text: 'Meeting tomorrow at 10AM', isRead: true},
        {text: 'Reminder: Submit the report', isRead: false},
      ],
      showNotifications: false,
      showMessages: false,
      showSearchResults: false, // Show search results dropdown
      filteredResults: [], // Store filtered search results
      showProfileMenu: false, // Control visibility of the profile dropdown menu
    };
  },
  computed: {
    // Compute the number of unread notifications
    unreadNotifications() {
      return this.notifications.filter(notification => !notification.isRead).length;
    },
    // Compute the number of unread messages
    unreadMessages() {
      return this.messages.filter(message => !message.isRead).length;
    }
  },
  methods: {
    async getUser() {
      let response = await this.$axios.request({
        method: "GET",
        url: "/user"
      });
      let logged_user = response.data.data;
      this.user.name = logged_user.first_name + ' ' + logged_user.last_name;

    },
    onSearchInput() {
      // Filter notifications and messages based on search query
      if (this.searchQuery.trim()) {
        this.filteredResults = [
          ...this.notifications.filter(notification => notification.text.toLowerCase().includes(this.searchQuery.toLowerCase())),
          ...this.messages.filter(message => message.text.toLowerCase().includes(this.searchQuery.toLowerCase())),
        ];
        this.showSearchResults = true; // Show results if search query is not empty
      } else {
        this.showSearchResults = false; // Hide results if search is cleared
      }
    },
    onNotificationsClick() {
      this.showNotifications = !this.showNotifications;
      this.showMessages = false; // Close messages if open
    },
    onMessagesClick() {
      this.showMessages = !this.showMessages;
      this.showNotifications = false; // Close notifications if open
    },
    markNotificationAsRead(index) {
      this.notifications[index].isRead = true;
      console.log('Marked notification as read:', this.notifications[index]);
    },
    markMessageAsRead(index) {
      this.messages[index].isRead = true;
      console.log('Marked message as read:', this.messages[index]);
    },
    toggleProfileMenu() {
      this.showProfileMenu = !this.showProfileMenu; // Toggle the profile menu visibility
    },
    goToProfile() {
      console.log('Navigate to profile');
      this.$router.push('/dashboard/profile'); // Navigate to the profile route
      this.showProfileMenu = false; // Close the profile menu after selecting an option
    },
    goToInbox(){
      console.log('Navigate to inbox');
      this.$router.push('/dashboard/inbox'); // Navigate to the inbox route
      this.showMessages = false; // Close the messages dropdown after selecting inbox
    },
    goToNotifications(){
      console.log('Navigate to notifications');
      this.$router.push('/dashboard/notifications'); // Navigate to the notifications route
    },
    goToSettings() {
      console.log('Navigate to settings');
      this.showProfileMenu = false; // Close the profile menu after selecting an option
    },
    logout() {
      this.showProfileMenu = false;
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  },
  created() {
    //this.getUser();
  }
};
</script>

<style scoped>
/* Styling for the profile dropdown menu */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.right-0 {
  right: 0;
}

.mt-2 {
  margin-top: 0.5rem;
}

.w-48 {
  width: 12rem;
}

.bg-white {
  background-color: white;
}

.border {
  border-width: 1px;
}

.border-gray-300 {
  border-color: #d1d5db;
}

.rounded {
  border-radius: 0.375rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.z-10 {
  z-index: 10;
}

.cursor-pointer {
  cursor: pointer;
}

.hover\:bg-gray-100:hover {
  background-color: #f7fafc;
}

.divide-y > * + * {
  border-top-width: 1px;
}

.divide-gray-200 > * + * {
  border-color: #e5e7eb;
}

/* Red dot styling for unread notifications and messages */
.w-3 {
  width: 0.75rem;
}

.h-3 {
  height: 0.75rem;
}

.bg-red-500 {
  background-color: #f56565;
}

.rounded-full {
  border-radius: 9999px;
}

.top-0 {
  top: -0.25rem;
}

.right-0 {
  right: -0.25rem;
}
</style>

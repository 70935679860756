<template>
  <DashboardLayout>
    <div class="flex justify-center items-center mt-6">
      <div class="container max-w-md bg-[#eeeeee] text-white p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">
          {{ $translate('brands.create_brand.title') }}
        </h2>
        <form @submit.prevent="createNewBrand">
          <div class="mb-4">
            <input
                type="text"
                v-model="brand.brandName"
                :disabled="isSubmitting"
                :placeholder="$translate('brands.create_brand.brand_name')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                :class="{ 'border-red-500': errors.brandName }"
            />
            <span v-if="errors.brandName" class="text-red-500 text-sm">{{ errors.brandName }}</span>
          </div>
          <div class="mb-4">
            <input
                type="text"
                v-model="brand.brandDescription"
                :disabled="isSubmitting"
                :placeholder="$translate('brands.create_brand.brand_description')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                :class="{ 'border-red-500': errors.brandDescription }"
            />
            <span v-if="errors.brandDescription" class="text-red-500 text-sm">{{ errors.brandDescription }}</span>
          </div>
          <div class="mb-4">
            <input
                type="text"
                v-model="brand.brandUrl"
                :disabled="isSubmitting"
                :placeholder="$translate('brands.create_brand.brand_url')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                :class="{ 'border-red-500': errors.brandUrl }"
            />
            <span v-if="errors.brandUrl" class="text-red-500 text-sm">{{ errors.brandUrl }}</span>
          </div>
          <div class="mb-4" v-show="this.role === this.$defaultUsergroups.superadmin">
<!--            Select Client -->
            <select
                v-model="brand.clientId"
                :disabled="isSubmitting"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            >
              <option value="" disabled selected>{{ $translate('brands.create_brand.select_client') }}</option>
              <option v-for="client in clients" :key="client.id" :value="client.id">
                {{ client.company_name }}
              </option>
            </select>

          </div>
          <div class="flex justify-center mt-6">
            <button
                type="submit"
                :disabled="isSubmitting || !brand.brandName || !brand.brandDescription || !brand.brandUrl || errors.hasErrors"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
            >
              Add Brand
            </button>
          </div>
        </form>
      </div>
      <!-- Error dialog -->
      <ModalDialogComponent
          :title="modalTitle"
          :message="modalMessage"
          :visible="showModal"
          @close="showModal = false"
      />
    </div>
  </DashboardLayout>
</template>

<script>
import { defineComponent, watch } from "vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import ModalDialogComponent from "@/components/Fields/ModalDialogComponent.vue";

export default defineComponent({
  components: { DashboardLayout, ModalDialogComponent },
  data() {
    return {
      showModal: false,
      modalTitle: "",
      isSubmitting: false,
      clients: [],
      role: this.$usergroup,
      modalMessage: "",
      brand: {
        brandName: "",
        brandDescription: "",
        brandUrl: "",
        clientId: "",
      },
      errors: {
        brandName: "",
        brandDescription: "",
        brandUrl: "",
        hasErrors: false,
      },
    };
  },
  methods: {
    async getClients(){
      let response = await this.$axios.request(
          {
            url: '/brands/clients',
            method: 'GET',
          }
      );
      this.clients = response.data.clients;

    },
    clearForm() {
      this.brand.brandName = "";
      this.brand.brandDescription = "";
      this.brand.brandUrl = "";
      this.errors = { brandName: "", brandDescription: "", brandUrl: "", hasErrors: false };
    },
    validateForm() {
      this.errors = { brandName: "", brandDescription: "", brandUrl: "", hasErrors: false };

      if (!this.brand.brandName) {
        this.errors.brandName = "Brand name is required.";
        this.errors.hasErrors = true;
      }

      if (!this.brand.brandDescription) {
        this.errors.brandDescription = "Brand description is required.";
        this.errors.hasErrors = true;
      }

      if (!this.brand.brandUrl) {
        this.errors.brandUrl = "Brand URL is required.";
        this.errors.hasErrors = true;
      } else if (!this.isValidUrl(this.brand.brandUrl)) {
        this.errors.brandUrl = "Please enter a valid URL.";
        this.errors.hasErrors = true;
      }

      return !this.errors.hasErrors;
    },
    isValidUrl(url) {
      const pattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;
      return pattern.test(url.trim());
    },
    async createNewBrand() {
      if (this.isSubmitting) return;
      if (!this.validateForm()) return;

      this.isSubmitting = true;
      try {
        console.log(this.brand);
        const response = await this.$axios.request({
          url: "/brands/id",
          method: "POST",
          data: this.brand,
        });

        if (response.data.status === "success") {
          this.$toast.success(this.$translate("brands.create_brand.messages.success"));
          this.$router.push("/dashboard/brands/manage");
        } else {
          this.$toast.error(this.$translate("brands.create_brand.messages.error"));
        }
      } catch (error) {
        console.error("Error adding brand:", error);
        this.$toast.error(this.$translate("brands.create_brand.messages.error"));
      } finally {
        this.isSubmitting = false;
      }
      this.clearForm();
    },
  },
  watch: {
    "brand.brandUrl"(newValue) {
      if (!newValue) {
        this.errors.brandUrl = "Brand URL is required.";
        this.errors.hasErrors = true;
      } else if (!this.isValidUrl(newValue)) {
        this.errors.brandUrl = "Please enter a valid URL.";
        this.errors.hasErrors = true;
      } else {
        this.errors.brandUrl = "";
        this.errors.hasErrors = false;
      }
    },
    "brand.brandName"(newValue) {
      if (!newValue) {
        this.errors.brandName = "Brand name is required.";
        this.errors.hasErrors = true;
      } else {
        this.errors.brandName = "";
        this.errors.hasErrors = false;
      }
    },
  },
  mounted() {
    if(this.role === this.$defaultUsergroups.superadmin)  this.getClients();
  },
});
</script>

<style scoped></style>

<template>
  <LandingPageLayout>
    <div class="min-h-screen flex items-center justify-center bg-[#eee]">
      <div class="w-full max-w-md p-8 space-y-6 bg-gray-800 rounded shadow-md">

        <img src="../assets/logo.png" class="h-12 w-12 mx-auto block" alt="Esofthall Logo" />
        <h2 class="text-2xl font-bold text-center text-white">{{ $translate('forgot_password.title') }}</h2>

        <form @submit.prevent="requestPasswordReset">
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ $translate('forgot_password.email_label') }}</label>
          <input
              type="email"
              v-model="email"
              autocomplete="email"
              class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
              required
          />

          <button
              type="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-8"
          >
            {{ $translate('forgot_password.submit') }}
          </button>
        </form>

        <p class="text-center text-sm text-gray-400">
          <router-link to="/login" class="text-orange-500 hover:underline">{{ $translate('forgot_password.back_to_login') }}</router-link>
        </p>

        <ModalDialogComponent
            :title="modalTitle"
            :message="modalMessage"
            :visible="showModal"
            @close="showModal = false"
        />
      </div>
    </div>
  </LandingPageLayout>
</template>

<script>
import LandingPageLayout from "@/views/LandingPageLayout.vue";
import ModalDialogComponent from "@/components/Fields/ModalDialogComponent.vue";
import axios from "@/module/axios";
import router from "@/module/router";

export default {
  components: {
    LandingPageLayout,
    ModalDialogComponent,
  },
  data() {
    return {
      email: "",
      showModal: false,
      modalTitle: "",
      modalMessage: "",
    };
  },
  methods: {
    async requestPasswordReset() {
      const payload = {email: this.email};

      try {
        const response = await this.$axios.request({
          url: "/user/forgot_password/",
          method: "POST",
          data: payload
        });

        if (response.data.status === "success") {
          this.modalTitle = this.$translate('forgot_password.success_title');
          this.modalMessage = this.$translate('forgot_password.success_message');
        } else {
          this.modalTitle = this.$translate('forgot_password.error_title');
          this.modalMessage = this.$translate('forgot_password.error_message');
        }

        this.showModal = true;
      } catch (error) {
        console.error(error);
        this.modalTitle = this.$translate('network_error_title');
        this.modalMessage = this.$translate('network_error_message');
        this.showModal = true;
      }
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <DashboardLayout>
    <div class="flex flex-col h-screen">
      <div class="flex-1 flex flex-col">
        <div class="flex-1 overflow-y-auto">
          <div class="container max-w-4xl mx-auto p-6 bg-[#eeeeee] text-white rounded-lg shadow-md ">
            <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Manage Commissions</h2>

            <!-- Commission List -->
            <div v-if="commissions.length > 0">
              <div class="flex flex-col bg-white rounded-md shadow-md mt-4">
                <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-md">
                  <div class="py-3 px-4 font-semibold flex-1 text-sm">Commission Name</div>
                  <div class="py-3 px-4 font-semibold flex-1 text-sm">Brand</div>
                  <div class="py-3 px-4 font-semibold flex-1 text-sm">Product</div>
                  <div class="py-3 px-4 font-semibold flex-1 text-sm">Commission Type</div>
                  <div class="py-3 px-4 font-semibold flex-none text-sm">Actions</div>
                </div>
                <div v-for="commission in commissions" :key="commission.id" class="flex border-b border-gray-400 hover:bg-gray-200 transition-colors duration-200">
                  <div class="py-3 text-black px-4 flex-1">{{ commission.commissionName }}</div>
                  <div class="py-3 text-black px-4 flex-1">{{ commission.brandName }}</div>
                  <div class="py-3 text-black px-4 flex-1">{{ commission.productName }}</div>
                  <div class="py-3 text-black px-4 flex-1">{{ commission.commissionType }}</div>
                  <div class="py-3 text-black px-4 flex-none">
                    <div class="flex justify-center gap-2 max-w-xs">
                      <button @click="viewDetails(commission)" :class="['w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white', 'bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4']">
                        <span class="material-icons">visibility</span>
                      </button>
                      <button @click="deleteCommission(commission.id)" :class="['w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white', 'bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4']">
                        <span class="material-icons">delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else>
              <p class="text-center text-gray-500">No commissions available.</p>
            </div>
          </div>
        </div>
      </div>

      <transition name="fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center z-50">
          <div class="fixed inset-0 bg-black opacity-50" @click="closeModal"></div>
          <div class="bg-white rounded-lg shadow-lg z-10 p-6 max-w-4xl w-full">
            <h2 class="text-2xl font-bold text-orange-500 mb-4">{{ isEditMode ? 'Edit Commission' : 'Add a New Commission' }}</h2>
            <form>

              <div class="mb-4">
                <label for="commissionName" class="text-sm font-semibold mb-1">Commission Name</label>
                <input
                    id="commissionName"
                    type="text"
                    v-model="newCommission.commissionName"
                    placeholder="Commission Name"
                    class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>

              <div class="mb-4">
                <label for="brandName" class="text-sm font-semibold mb-1">Brand Name</label>
                <select
                    id="brandName"
                    v-model="newCommission.brandId"
                    class="p-2 w-full rounded bg-gray-200 text-black focus:outline-none focus:ring-2 focus:ring-orange-500"
                >
                  <option value="" disabled>Select a brand</option>
                  <option v-for="brand in brands" :key="brand.id" :value="brand.id" :selected="brand.id === newCommission.brandId">
                    {{ brand.name }}
                  </option>
                </select>
              </div>

              <div class="mb-4">
                <label for="productName" class="text-sm font-semibold mb-1">Product Name</label>
                <select
                    id="productName"
                    v-model="newCommission.productName"
                    class="p-2 w-full rounded bg-gray-200 text-black focus:outline-none focus:ring-2 focus:ring-orange-500"
                >
                  <option value="" disabled>Select a product</option>
                  <option v-for="product in products" :key="product.id" :value="product.name" :selected="product.id === newCommission.productId">
                    {{ product.name }}
                  </option>

                </select>
              </div>


              <!-- Commission Type Field -->
              <div class="mb-4">
                <label for="commissionType" class="text-sm font-semibold mb-1">Commission Type</label>
                <select
                    id="commissionType"
                    v-model="newCommission.commissionType"
                    class="p-2 w-full rounded bg-gray-200 text-black focus:outline-none focus:ring-2 focus:ring-orange-500"
                >
                  <option value="flat">Flat Rate</option>
                  <option value="tiered">Tiered</option>
                </select>
              </div>

              <!-- Tiered Commission Fields -->
              <div v-if="newCommission.commissionType === 'tiered'">
                <div v-for="(tier, index) in newCommission.tiers" :key="index" class="flex gap-4 items-center border-b py-2">
                  <div class="flex flex-col w-1/4">
                    <label for="amountMin" class="text-sm font-semibold mb-1">Min</label>
                    <input
                        type="number"
                        v-model.number="tier.amountMin"
                        placeholder="Min"
                        class="form-input border rounded px-3 py-2"
                    />
                  </div>
                  <div class="flex flex-col w-1/4">
                    <label for="amountMax" class="text-sm font-semibold mb-1">Max</label>
                    <input
                        type="number"
                        v-model.number="tier.amountMax"
                        placeholder="Max"
                        class="form-input border rounded px-3 py-2"
                    />
                  </div>
                  <div class="flex flex-col w-1/4">
                    <label for="rate" class="text-sm font-semibold mb-1">Rate (%)</label>
                    <input
                        type="number"
                        v-model.number="tier.rate"
                        placeholder="Rate (%)"
                        class="form-input border rounded px-3 py-2"
                    />
                  </div>
                  <button @click.prevent="removeTier(index)" class="text-admin-200 material-icons text-xl">delete</button>
                </div>
                <button @click.prevent="addTier" class="mt-2 text-admin-200 material-icons">add_circle_outline</button>
              </div>

              <!-- Flat Rate Commission Fields -->
              <div v-if="newCommission.commissionType === 'flat'">
                <div class="mb-4">
                  <label for="flatRate" class="text-sm font-semibold mb-1">Fixed Rate (%)</label>
                  <input
                      id="flatRate"
                      type="number"
                      v-model="newCommission.flatRate"
                      placeholder="Fixed Rate (%)"
                      class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  />
                </div>
              </div>

              <!-- Submit Button -->
              <div class="flex justify-center mt-6">
                <ButtonComponent
                    class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800"
                    @click ="updateCommission()"
                >
                  Update Commission
                </ButtonComponent>
              </div>
            </form>
            <ButtonComponent @click="closeModal" class="bg-orange-600 text-white px-4 py-2 rounded mt-4 hover:bg-orange-800">
              Close
            </ButtonComponent>
          </div>
        </div>
      </transition>

    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import {toRaw} from 'vue';

export default {
  components: { DashboardLayout, ButtonComponent },
  data() {
    return {
      commissions: [],
      isModalOpen: false,
      isEditMode: false,
      brands:{},
      products:{},
      newCommission: {
        id: null,
        commissionName: '',
        brandName: '',
        productName: '',
        commissionType: 'Flat', // default to flat
        tiers : [],
      },
    };
  },
  methods: {
    addTier() {
      const lastTier = this.newCommission.tiers[this.newCommission.tiers.length - 1];
      const newMin = lastTier ? lastTier.amountMax + 1 : 0;

      this.newCommission.tiers.push({
        amountMin: newMin,
        amountMax: null,
        rate: 0
      });
    },
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      el.style.transition = 'opacity 0.5s';
      el.style.opacity = 1;
      done();
    },
    leave(el, done) {
      el.style.transition = 'opacity 0.5s';
      el.style.opacity = 0;
      done();
    },
    async fetchCommissions() {
      try {
        const response = await this.$axios.get('/commissions');

        this.brands = response.data.brands;
        this.products = response.data.products;

        this.commissions = response.data.commissions.map((commission) => {
          if (commission.commission_type === 'flat') {
            return {
              id: commission.id,
              commissionName: commission.commission_name,
              brandName: this.brands[commission.brand_id].name,
              brandId: commission.brand_id,
              productName: this.products[commission.product_id].name,
              productId: commission.product_id,
              commissionType: commission.commission_type,
              flatRate: commission.flat_rate,
            };

          } else if (commission.commission_type === 'tiered') {
            let tiers = JSON.parse(commission.tiers || '[]');

            return {
              id: commission.id,
              commissionName: commission.commission_name,
              brandName: this.brands[commission.brand_id].name,
              brandId: commission.brand_id,
              productName: this.products[commission.product_id].name,
              productId: commission.product_id,
              commissionType: commission.commission_type,
              tiers: tiers,
            };
          }

        });
      } catch (error) {
        console.error('Error fetching commissions:', error);
      }
    },
    viewDetails(commission) {
      this.isModalOpen = true;
      this.isEditMode = true;
      this.newCommission = { ...commission };
    },
    async deleteCommission(commissionId) {
      let response = await this.$axios.delete('/commissions/' + commissionId);

      if(response.status === 200) {
        this.$toast.success('Commission deleted successfully');
        this.commissions = this.commissions.filter((commission) => commission.id !== commissionId);
      }
      else{
        this.$toast.fail('Failed to delete commission');
      }




    },
    openModal() {
      this.isModalOpen = true;
      this.isEditMode = false; // Add mode by default
      this.newCommission = { id: null, commissionName: '', brandName: '', productName: '', commissionType: 'flat' }; // Reset the form
    },
    closeModal() {
      this.isModalOpen = false;
      this.resetForm();
    },
    handleSubmit() {
      if (!this.newCommission.commissionName || !this.newCommission.brandName || !this.newCommission.productName) {
        alert('Please fill in all fields');
        return;
      }
      if (this.isEditMode) {
        this.updateCommission();
      }
      this.closeModal();
    },
    removeTier(index) {
      this.newCommission.tiers.splice(index, 1);
    },
    async updateCommission() {
      let response = await this.$axios.request({
          url: `/commissions/index/${this.newCommission.id}`,
          method: 'PUT',
          data: this.newCommissions
      });


      console.log('response', response);

      if(response.status !== 200) {
        this.$toast.fail('Failed to update commission');
      }

      this.$toast.success('Commission updated successfully');
      this.closeModal();





      const index = this.commissions.findIndex(commission => commission.id === this.newCommission.id);
      if (index !== -1) {
        this.commissions[index] = { ...this.newCommission };
      }
    },
    resetForm() {
      this.newCommission = { id: null, commissionName: '', brandName: '', productName: '', commissionType: 'flat' };
    },
  },
  mounted(){
    this.fetchCommissions();
  }
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto;
}

table {
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
}

th, td {
  padding: 1rem;
  text-align: left;
  border: 1px solid #ddd;
}

th {
  background-color: #eee;
}

button {
  cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
   transition: opacity 0.3s ease;
 }
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
<template>
  <DashboardLayout>
    <div class="flex flex-col h-screen bg-gray-100">
      <!-- Main content area -->
      <div class="flex-1 flex flex-col p-8">
        <!-- Notification Counters -->
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
          <div class="bg-orange-500 text-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow">
            <p class="text-lg font-semibold">Total Notifications</p>
            <p class="text-2xl">{{ notifications.length }}</p>
          </div>
          <div class="bg-orange-500 text-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow">
            <p class="text-lg font-semibold">Unread Notifications</p>
            <p class="text-2xl">{{ unreadCount }}</p>
          </div>
          <div class="bg-orange-500 text-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow">
            <p class="text-lg font-semibold">Important Notifications</p>
            <p class="text-2xl">{{ importantCount }}</p>
          </div>
        </div>

        <!-- Notification Center -->
        <div class="bg-white rounded-lg shadow-lg p-6 mb-8">
          <div class="flex items-center justify-between mb-6">
            <h2 class="text-3xl font-bold text-orange-600">Notifications</h2>
            <button
                class="bg-orange-600 text-white px-6 py-3 rounded-lg hover:bg-orange-700 transition-colors"
                @click="openNotificationModal"
            >
              Create New Notification
            </button>
          </div>

          <!-- Notification List -->
          <div class="overflow-x-auto">
            <div class="min-w-full bg-gray-100 rounded-lg">
              <div class="flex justify-between bg-orange-500 text-white p-4 rounded-t-lg">
                <div class="w-1/4 text-center">Title</div>
                <div class="w-1/4 text-center">Message</div>
                <div class="w-1/4 text-center">Date</div>
                <div class="w-1/6 text-center">Status</div>
                <div class="w-1/6 text-center">Actions</div>
              </div>
              <div
                  v-for="notification in filteredNotifications"
                  :key="notification.id"
                  class="flex items-center border-b border-gray-300 hover:bg-gray-50 transition-colors"
              >
                <div class="w-1/4 text-center py-4 px-6">{{ notification.title }}</div>
                <div class="w-1/4 text-center py-4 px-6">{{ notification.message }}</div>
                <div class="w-1/4 text-center py-4 px-6">{{ notification.date }}</div>
                <div class="w-1/6 text-center py-4 px-6">
                  {{ notification.isRead ? 'Read' : 'Unread' }}
                </div>
                <div class="w-1/6 text-center py-4 px-6">
                  <div class="flex justify-center space-x-2">
                    <button
                        @click="viewNotification(notification)"
                        class="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors"
                    >
                      <span class="material-icons">visibility</span>
                    </button>
                    <button
                        @click="deleteNotification(notification.id)"
                        class="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors"
                    >
                      <span class="material-icons">delete</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Notification Modal -->
      <transition name="fade">
        <div
            v-show="isNotificationModalOpen"
            class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          <div
              class="bg-white rounded-lg shadow-lg z-10 p-8 max-w-lg w-full transform transition-all duration-300 ease-in-out scale-95 hover:scale-100"
          >
            <h2 class="text-2xl font-bold text-orange-600 mb-4">
              {{ isEditMode ? 'Edit Notification' : 'Create New Notification' }}
            </h2>
            <form @submit.prevent="sendNewNotification">
              <div class="mb-6">
                <input
                    type="text"
                    v-model="newNotification.title"
                    placeholder="Notification Title"
                    class="p-4 w-full rounded-lg bg-gray-200 text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>
              <div class="mb-6">
                <input
                    type="text"
                    v-model="newNotification.recipients"
                    placeholder="Notification Recipients"
                    class="p-4 w-full rounded-lg bg-gray-200 text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>
              <div class="mb-6">
                <textarea
                    v-model="newNotification.message"
                    placeholder="Notification Message"
                    rows="4"
                    class="p-4 w-full rounded-lg bg-gray-200 text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
                ></textarea>
              </div>
              <div class="flex justify-center gap-4 mb-6">
                <button
                    type="submit"
                    class="bg-orange-600 text-white px-6 py-3 rounded-lg hover:bg-orange-700 transition-colors"
                >
                  {{ isEditMode ? 'Update Notification' : 'Send Notification' }}
                </button>
                <button
                    @click="closeNotificationModal"
                    class="bg-orange-600 text-white px-6 py-3 rounded-lg hover:bg-orange-700 transition-colors"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout },
  data() {
    return {
      notifications: [], // Initialize as an empty array
      filteredNotifications: [],
      isNotificationModalOpen: false,
      isEditMode: false,
      newNotification: {
        id: null,
        title: "",
        recipients: "",
        message: "",
      },
    };
  },
  created() {
    this.fetchAllNotifications();
    this.filterNotifications("all");
  },
  computed: {
    unreadCount() {
      // Ensure notifications is always an array before filtering
      return Array.isArray(this.notifications)
          ? this.notifications.filter((n) => !n.isRead).length
          : 0;
    },
    importantCount() {
      return Array.isArray(this.notifications)
          ? this.notifications.filter((n) => n.isImportant).length
          : 0;
    },
    filteredNotifications() {
      return Array.isArray(this.notifications) ? this.notifications : [];
    },
  },
  methods: {
    async sendNewNotification() {
      try {
        const payload = {
          title: this.newNotification.title,
          target_id: this.newNotification.recipients,
          message: this.newNotification.message,
        };
        console.log('Sending the payload...', payload);
        const response = await this.$axios.post("/dashboard/notifications", payload);
        console.log("Notification sent successfully:", response.data);

        // Add the new notification to the notifications list reactively
        this.notifications.push(response.data); // Add the new notification to the list

        // Reset form fields
        this.newNotification = { id: null, title: "", recipients: "", message: "" };

        // Close the modal
        this.closeNotificationModal();

      } catch (error) {
        console.error("Error sending notification:", error);
        alert("Failed to send notification. Please try again.");
      }
    },
    async fetchAllNotifications() {
      try {
        const response = await this.$axios.get('/dashboard/notifications');
        // Check if the response data is an array
        if (Array.isArray(response.data)) {
          console.log(response.data);
          this.notifications = response.data;
        } else {
          console.error("Expected an array of notifications, but received:", response.data);
          this.notifications = []; // Fall back to an empty array
        }

        // You can also filter the notifications after fetching if needed
        this.filterNotifications("all"); // Optional, to filter by all notifications
      } catch (error) {
        console.error("Error fetching notifications:", error);
        alert("Failed to fetch notifications. Please try again.");
      }
    },
    filterNotifications(status) {
      if (!Array.isArray(this.notifications)) return;

      if (status === "all") {
        this.filteredNotifications = this.notifications;
      } else if (status === "unread") {
        this.filteredNotifications = this.notifications.filter((n) => !n.isRead);
      } else if (status === "read") {
        this.filteredNotifications = this.notifications.filter((n) => n.isRead);
      }
    },
    viewNotification(notification) {
      console.log("Viewing notification:", notification);
      notification.isRead = true;
    },
    openNotificationModal() {
      this.isNotificationModalOpen = true;
      this.isEditMode = false;
      this.newNotification = { id: null, title: "", recipients: "", message: "" };
    },
    closeNotificationModal() {
      this.isNotificationModalOpen = false;
      this.isEditMode = false;
    },
    deleteNotification(id) {
      this.notifications = this.notifications.filter((n) => n.id !== id);
    },
  },
};
</script>

<style scoped>
/* Your custom styles here */
</style>

<template>
  <div class="flex items-center justify-between p-4 border-b border-gray-700">
    <img src="../../../assets/logo.png" class="h-12 w-12 mx-3 block" />
    <h2 class="text-lg font-bold" v-if="!isCollapsed">Affiliates</h2>
    <button @click="toggleSidebar" class="focus:outline-none">
      <span class="material-icons pt-2" v-if="isCollapsed">menu</span>
      <span v-else class="material-icons mx-3.5 pt-2">close</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    isCollapsed: {
      type: Boolean,
      required: true
    },
  },
  methods: {
    toggleSidebar() {
      this.$emit('toggle-sidebar');
    },
  },
};
</script>

<style scoped>
.material-icons {
  font-family: 'Material Icons', serif;
  font-size: 24px;
}
</style>

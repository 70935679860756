<template>
  <LandingPageLayout>
    <div class="min-h-screen flex items-center justify-center bg-[#eee]">
      <div class="w-full max-w-md p-8 space-y-6 bg-gray-800 rounded shadow-md">
        <img src="../assets/logo.png"  class="h-12 w-12 mx-auto block" />
        <h2 class="text-2xl font-bold text-center text-white">{{ ($translate('reset_password')) }}</h2>
        <form @submit.prevent="resetPassword">
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('Reset Token')) }}</label>
          <input
              type="text"
              v-model="resetToken"
              class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
              disabled
          />
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('new_password')) }}</label>
          <input
              type="password"
              v-model="newPassword"
              class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
              required
          />
          <label class="block text-sm font-medium text-gray-300 mt-4">{{ ($translate('confirm_new_password')) }}</label>
          <input
              type="password"
              v-model="confirmNewPassword"
              class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
              required
          />
          <button
              type="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-12"
          >
            {{ ($translate('Submit')) }}
          </button>
        </form>
      </div>
    </div>
    <ModalDialogComponent
        :title="modalTitle"
        :message="modalMessage"
        :visible="showModal"
        @close="showModal = false"
    />


  </LandingPageLayout>
</template>

<script>
import LandingPageLayout from "@/views/LandingPageLayout.vue";
import ModalDialogComponent from "@/components/Fields/ModalDialogComponent.vue";
import axios from "@/module/axios";
import router from "@/module/router";

export default {
  components: {
    LandingPageLayout,
    ModalDialogComponent,
  },
  data() {
    return {
      resetToken: this.$route.params.token,
      newPassword: "",
      confirmNewPassword: "",
      showModal: false,
      modalTitle: "",
      modalMessage: "",
    };
  },
  methods: {
    async resetPassword() {
      if (this.newPassword !== this.confirmNewPassword) {
        this.$toast.error("Passwords do not match");
        return;
      }
      try {
        let response = await this.$axios.request({
          url: "/reset_password/token/" + this.resetToken,
          method: "POST",
          data: {
            token: this.resetToken,
            password: this.newPassword,
          },
        });

        if(response.data.status === 'failed'){
          this.$toast.error(response.data.message);
          return;
        }

        this.$toast.success("Password reset successfully");
        setTimeout(() => {
          router.push("/login");
        }, 2000);
      } catch (error) {
        this.modalTitle = this.$translate("Error");
        this.modalMessage = this.$translate("Failed to reset password");
        this.showModal = true;
      }
    },
  },
};
</script>

<style scoped>
</style>

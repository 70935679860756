<template>
  <DashboardLayout>
    <div class="flex justify-center items-center">
      <div class="container max-w-md bg-[#eeeeee] text-white p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Add a New Commission</h2>
        <form @submit.prevent="handleAddCommission">

          <!-- Commission Details -->
          <h3 class="text-xl text-orange-500 font-semibold mb-2">Commission Details</h3>
          <div class="mb-4">
            <input
                type="text"
                v-model="commissionName"
                placeholder="Commission Name"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                :class="{'border-red-500': !commissionName}"
                required
            />
          </div>
          <div class="mb-4">
            <select v-model="selectedBrand" @change="filterProductsByBrand" class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500" :class="{'border-red-500': !selectedBrand}">
              <option value="">Select Brand</option>
              <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
            </select>
          </div>

          <div class="mb-4">
            <select v-model="selectedProduct" :disabled="loadingProducts" class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500">
              <option value="">Select Product</option>
              <option v-for="product in filteredProducts" :key="product.id" :value="product.id">{{ product.name }}</option>
            </select>
            <div v-if="loadingProducts" class="text-sm text-gray-500">Loading products...</div>
          </div>

          <!-- Commission Type -->
          <h3 class="text-xl text-orange-500 font-semibold mb-2">Select Commission Type</h3>
          <div class="mb-4">
            <select v-model="commissionType" @change="onCommissionTypeChange" class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500" :class="{'border-red-500': !commissionType}">
              <option value="" disabled>Select Type</option>
              <option value="flat">Flat Rate</option>
              <option value="tiered">Tiered</option>
            </select>
          </div>

          <div v-if="commissionType === 'flat'" class="mb-4">
            <input
                type="number"
                v-model="flatRate"
                placeholder="Flat Rate Percentage"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
            />
          </div>

          <div v-if="commissionType === 'tiered'" class="mb-4">
            <h4 class="text-lg text-orange-500 font-semibold mb-2">Tier 1</h4>
            <div class="flex mb-2">
              <input
                  type="number"
                  v-model="tiers[0].amountMin"
                  placeholder="Amount Min"
                  class="p-2 flex-1 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 mr-2"
                  required
              />
              <input
                  type="number"
                  v-model="tiers[0].amountMax"
                  placeholder="Amount Max"
                  class="p-2 flex-1 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
              />
            </div>
            <div class="flex">
              <input
                  type="number"
                  v-model="tiers[0].rate"
                  placeholder="Rate (%)"
                  min="0"
                  class="p-2 flex-1 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
              />
            </div>

            <!-- Dynamic Tier Inputs -->
            <div v-for="(tier, index) in tiers.slice(1)" :key="index">
              <h4 class="text-lg text-orange-500 font-semibold mt-4 mb-2">Tier {{ index + 2 }}</h4>
              <div class="flex mb-2">
                <input
                    type="number"
                    v-model="tier.amountMin"
                    placeholder="Amount Min"
                    class="p-2 flex-1 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 mr-2"
                    required
                />
                <input
                    type="number"
                    v-model="tier.amountMax"
                    placeholder="Amount Max"
                    class="p-2 flex-1 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                    required
                />
              </div>
              <div class="flex">
                <input
                    type="number"
                    v-model="tier.rate"
                    placeholder="Rate (%)"
                    min="0"

                    class="p-2 flex-1 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                    required
                />
              </div>
            </div>

            <!-- Add More Tier Button -->
            <div class="flex justify-end mt-2">
              <button type="button" @click="addTier" class="flex items-center text-xl text-admin-200">
                <span class="material-icons">add</span>
              </button>
            </div>
          </div>

          <div class="flex justify-center mt-6">
            <ButtonComponent
                type="submit"
                class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800"
                :disabled="!isFormValid"
            >
              Add Commission
            </ButtonComponent>
          </div>
        </form>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout, ButtonComponent },
  data() {
    return {
      commissionName: '',
      selectedBrand: "",
      selectedProduct: "",
      commissionType: '',
      flatRate: null,
      tiers: [
        { amountMin: null, amountMax: null, rate: null },
      ],
      brands: [
        { id: 1, name: "Brand A" },
        { id: 2, name: "Brand B" },
      ],
      products: [
        { id: 1, name: "Product X", brandId: 1 },
        { id: 2, name: "Product Y", brandId: 2 },
      ],
      filteredProducts: [
        { id: 1, name: "Product X", brandId: 1 },
        { id: 2, name: "Product Y", brandId: 2 },
      ],
      loadingProducts: false,
    };
  },
  computed: {
    isFormValid() {
      return this.commissionName && this.selectedBrand && this.selectedProduct && this.commissionType && this.isCommissionFieldsValid();
    },
  },
  methods: {
    filterProductsByBrand(id) {
      if (id) {
        this.loadingProducts = true;
        setTimeout(() => {
          // this.filteredProducts = this.products.filter((product) => product.brandId === parseInt(id));
          this.selectedProduct = '';
          this.loadingProducts = false;
        }, 500);
      } else {
        this.filteredProducts = this.products;
        this.selectedProduct = '';
        this.loadingProducts = false;
      }
    },
    addTier() {
      this.tiers.push({ amountMin: null, amountMax: null, rate: null });
    },
    onCommissionTypeChange() {
      if (this.commissionType !== 'flat') this.flatRate = null;
      if (this.commissionType !== 'tiered') {
        this.tiers = [{ amountMin: null, amountMax: null, rate: null }];
      }
    },
    isCommissionFieldsValid() {
      if (this.commissionType === 'flat') {
        return this.flatRate !== null;
      } else if (this.commissionType === 'tiered') {
        return this.tiers.every(tier => tier.amountMin && tier.amountMax && tier.rate);
      }
      return false;
    },
    async fetchBrands() {
      try {
        const response = await this.$axios.request({
            url: '/brands',
            method: 'GET'
        });
        this.brands = response.data;
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchProducts() {
      try {
        const response = await this.$axios.request({
          method: "GET",
          url: "/products",
        });
        // Assuming the response structure is { data: { products: [...] } }
        this.filteredProducts = response.data.products.map((product) => ({
          id: product.id,
          name: product.name,
        }));
        console.log(this.filteredProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    async handleAddCommission() {
      console.log('Commission added:', {
        commissionName: this.commissionName,
        selectedBrand: this.selectedBrand,
        selectedProduct: this.selectedProduct,
        commissionType: this.commissionType,
        flatRate: this.flatRate,
        tiers: this.tiers,
      });

      const payload = {
        commissionName: this.commissionName,
        selectedBrand: this.selectedBrand,
        selectedProduct: this.selectedProduct,
        commissionType: this.commissionType,
        flatRate: this.flatRate,
        tiers: this.tiers,
      };


      //let response = await this.$axios.post('/dashboard/commission', payload);
      const response = await this.$axios.post('/commissions', payload
      );

      console.log('Posting data...' , response);




    }
  },
  mounted(){
    this.fetchBrands();
    this.fetchProducts();
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>

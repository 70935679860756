<template>
  <DashboardLayout>
    <div class="flex justify-center items-center bg-gradient-white  overflow-y-auto mt-6">



      <div class="container max-w-lg bg-[#eeeeee] text-black p-6 shadow-md rounded-lg ">



        <h1 class="text-3xl font-bold mb-6 text-center text-orange-500">Dashboard Overview</h1>
        <div class="text-center text-gray-500">
          Statistics will be loaded when available.
        </div>


<!--        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">-->
<!--          &lt;!&ndash; Users Overview Card &ndash;&gt;-->
<!--          <div class="bg-white p-4 shadow-md rounded-lg">-->
<!--            <h2 class="text-xl text-admin-200 font-semibold mb-2">Users Overview</h2>-->
<!--            <canvas id="usersChart"></canvas>-->
<!--          </div>-->

<!--          &lt;!&ndash; Affiliates Overview Card &ndash;&gt;-->
<!--          <div class="bg-white p-4 shadow-md rounded-lg">-->
<!--            <h2 class="text-xl text-admin-200 font-semibold mb-2">Affiliates Overview</h2>-->
<!--            <canvas id="affiliatesChart"></canvas>-->
<!--          </div>-->

<!--          &lt;!&ndash; Commissions Overview Card &ndash;&gt;-->
<!--          <div class="bg-white p-4 shadow-md rounded-lg">-->
<!--            <h2 class="text-xl text-admin-200 font-semibold mb-2">Commission Overview</h2>-->
<!--            <canvas id="commissionsChart"></canvas>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">-->
<!--          &lt;!&ndash; Media Overview Card &ndash;&gt;-->
<!--          <div class="bg-white p-4 shadow-md rounded-lg">-->
<!--            <h2 class="text-xl text-admin-200 font-semibold mb-2">Media Overview</h2>-->
<!--            <canvas id="mediaChart"></canvas>-->
<!--          </div>-->

<!--          &lt;!&ndash; Payouts Overview Card &ndash;&gt;-->
<!--          <div class="bg-white p-4 shadow-md rounded-lg">-->
<!--            <h2 class="text-xl text-admin-200 font-semibold mb-2">Payouts Overview</h2>-->
<!--            <canvas id="payoutsChart"></canvas>-->
<!--          </div>-->
<!--        </div>-->




      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import DashboardLayout from '@/views/DashboardLayout.vue';
export default {
  name: 'DashboardOverview',
  components: { DashboardLayout },
  // mounted() {
  //   Chart.register(...registerables);
  //   this.renderCharts();
  // },
  methods: {
    // renderCharts() {
    //   // Users Overview Chart
    //   const usersCtx = document.getElementById('usersChart').getContext('2d');
    //   new Chart(usersCtx, {
    //     type: 'bar',
    //     data: {
    //       labels: ['User A', 'User B', 'User C', 'User D'],
    //       datasets: [{
    //         label: 'Users',
    //         data: [50, 75, 100, 25],
    //         backgroundColor: 'rgba(255, 99, 132, 0.2)',
    //         borderColor: 'rgba(255, 99, 132, 1)',
    //         borderWidth: 1,
    //       }]
    //     },
    //     options: {
    //       scales: {
    //         y: {
    //           beginAtZero: true
    //         }
    //       }
    //     }
    //   });
    //
    //   // Affiliates Overview Chart
    //   const affiliatesCtx = document.getElementById('affiliatesChart').getContext('2d');
    //   new Chart(affiliatesCtx, {
    //     type: 'line',
    //     data: {
    //       labels: ['Jan', 'Feb', 'Mar', 'Apr'],
    //       datasets: [{
    //         label: 'Affiliates',
    //         data: [20, 30, 60, 90],
    //         fill: false,
    //         borderColor: 'rgba(54, 162, 235, 1)',
    //         tension: 0.1
    //       }]
    //     },
    //     options: {}
    //   });
    //
    //   // Commission Overview Chart
    //   const commissionsCtx = document.getElementById('commissionsChart').getContext('2d');
    //   new Chart(commissionsCtx, {
    //     type: 'pie',
    //     data: {
    //       labels: ['Commission A', 'Commission B', 'Commission C'],
    //       datasets: [{
    //         label: 'Commissions',
    //         data: [300, 50, 150],
    //         backgroundColor: ['rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)'],
    //         borderColor: ['rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)'],
    //         borderWidth: 1
    //       }]
    //     },
    //     options: {}
    //   });
    //
    //   // Media Overview Chart
    //   const mediaCtx = document.getElementById('mediaChart').getContext('2d');
    //   new Chart(mediaCtx, {
    //     type: 'doughnut',
    //     data: {
    //       labels: ['Media A', 'Media B', 'Media C'],
    //       datasets: [{
    //         label: 'Media Engagement',
    //         data: [40, 30, 30],
    //         backgroundColor: ['rgba(255, 159, 64, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
    //         borderColor: ['rgba(255, 159, 64, 1)', 'rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
    //         borderWidth: 1
    //       }]
    //     },
    //     options: {}
    //   });
    //
    //   // Payouts Overview Chart
    //   const payoutsCtx = document.getElementById('payoutsChart').getContext('2d');
    //   new Chart(payoutsCtx, {
    //     type: 'radar',
    //     data: {
    //       labels: ['January', 'February', 'March', 'April'],
    //       datasets: [{
    //         label: 'Payouts',
    //         data: [60, 50, 80, 70],
    //         backgroundColor: 'rgba(153, 102, 255, 0.2)',
    //         borderColor: 'rgba(153, 102, 255, 1)',
    //         borderWidth: 1
    //       }]
    //     },
    //     options: {}
    //   });
    // }
  }
};
</script>

<style scoped>
.container {
  max-width: 80rem;
}
</style>

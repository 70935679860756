<template>
  <div v-if="visible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="w-full max-w-sm p-6 bg-gray-800 rounded-lg shadow-md text-white">
      <h3 class="text-lg text-admin-200 font-bold mb-4">{{ title }}</h3>
      <p>{{ message }}</p>
      <div class="mt-4 flex justify-end">
        <button
            @click="closeDialog"
            class="px-4 py-2 bg-orange-500 hover:bg-orange-600 rounded-md text-white"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title : String,
    message: String,
    visible: Boolean,
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
/* Add any additional styling or customization you need */
</style>

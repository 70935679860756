<template>
  <li>
    <a @click="toggleSubMenu" class="flex items-center p-2 hover:bg-gray-700 cursor-pointer w-auto">
      <span class="material-icons">{{ icon }}</span>
      <span class="ml-2">{{ title }}</span>
      <span v-if="isOpen" class="ml-auto text-orange-700 material-icons font-extrabold">expand_more</span>
      <span v-else class="ml-auto text-orange-500 material-icons">play_arrow</span>
    </a>
    <ul v-show="isOpen" class="ml-4">
      <li v-for="(subItem, index) in subItems" :key="index">
        <!-- Use router-link for dynamic routing based on subItem.path -->
        <router-link
            :to="subItem.path"
            class="block p-2 hover:bg-gray-600"
        >
          {{ subItem.name }}
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    subItems: {
      type: Array,
      default: () => []
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleSubMenu() {
      this.$emit('toggle'); // Emit event to parent to toggle the submenu
    }
  }
}
</script>

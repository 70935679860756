<template>
  <DashboardLayout>
    <div class="flex justify-center items-center bg-gradient-white">
      <div class="container max-w-7xl bg-[#eeeeee] text-black p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Campaigns</h2>
        <SearchComponent
            :value="searchQuery"
            @update:value="searchQuery = $event"
            v-model="searchQuery"
            :placeholder="$translate('placeholders.search.by_name')"
        />
        <!-- Header row -->
        <div class="flex bg-orange-500 text-white py-3 rounded-t-lg font-bold">

          <div class="flex-[2] text-center">Campaign Name</div>
          <div class="flex-[2] text-center">Tracking ID</div>
          <div class="flex-[1.5] text-center">Affiliate</div>
          <div v-show="this.role === this.$defaultUsergroups.superadmin" class="flex-[1.5] text-center">Client</div>
          <div class="flex-[1.5] text-center">Actions</div>
        </div>
        <!-- Data rows -->
        <div
            v-for="track in paginatedCampaigns"
            :key="track.id"
            class="flex items-center border-b border-gray-300 hover:bg-gray-300 transition-colors duration-200 py-3"
        >
          <div class="flex-[2] text-center">{{ track.campaign_name }}</div>
          <div class="flex-[2] text-center">{{ track.tracking_id }}</div>
          <div class="flex-[1.5] text-center">{{ track.affiliate_name }}</div>
          <div v-show="this.role === this.$defaultUsergroups.superadmin" class="flex-[1.5] text-center">{{ track.client_name }}</div>
          <div class="flex-[1.5] flex justify-center gap-2">
            <button
                class="w-1/2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none"
                @click="viewCampaign(track.tracking_id)"
            >
              <span class="material-icons">visibility</span>
            </button>
          </div>
        </div>
        <PaginationComponent
            :currentPage="currentPageOut"
            :totalPages="totalPagesOut"
            @update:currentPage="currentPageOut = $event"
            />
      </div>
    </div>

    <transition name="fade">
      <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div class="bg-white p-8 rounded-lg shadow-lg w-xl max-w-4xl mx-4 transform transition-all duration-300 ease-in-out scale-95 hover:scale-100">
          <h2 class="text-2xl font-bold text-admin-200 mb-4 text-center">Campaign Details</h2>

          <div v-if="metadata">
            <!-- Metadata Section -->
            <div class="mb-4">
              <label for="trackingId" class="block font-semibold">Tracking ID</label>
              <input
                  v-model="metadata.tracking_id"
                  type="text"
                  id="trackingId"
                  class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  disabled
              />
            </div>
            <div class="mb-4">
              <label for="redirectUrl" class="block font-semibold">Redirect URL</label>
              <input
                  v-model="metadata.redirect_url"
                  type="text"
                  id="redirectUrl"
                  class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                  disabled
              />
            </div>
          </div>

          <!-- Tracking Data Section -->
          <div v-if="selectedCampaign && selectedCampaign.length" class="mt-6">
            <h3 class="text-xl text-center font-bold text-admin-200 mb-2">Tracking Data</h3>
            <div class="overflow-x-auto">
              <table class="min-w-full bg-white border border-gray-300 rounded-md">
                <thead class="bg-admin-200">
                <tr>
                  <th class="text-left text-white px-4 py-2 border-b">Referrer URL</th>
                  <th class="text-left text-white px-4 py-2 border-b">Click ID (bid)</th>
                  <th class="text-left text-white px-4 py-2 border-b">Geo Location</th>
                  <th class="text-left text-white px-4 py-2 border-b">Click Timestamp</th>
                  <th class="text-left text-white px-4 py-2 border-b">Device Type</th>
                  <th class="text-left text-white px-4 py-2 border-b">OS</th>
                  <th class="text-left text-white px-4 py-2 border-b">Browser</th>
                  <th class="text-left text-white px-4 py-2 border-b">IP Address</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(row, index) in paginatedData"
                    :key="index"
                    class="hover:bg-gray-100 transition-colors"
                >
                  <td class="px-4 py-2 border-b">{{ row.referrer_url }}</td>
                  <td class="px-4 py-2 border-b">{{ row.bid }}</td>
                  <td class="px-4 py-2 border-b">{{ row.geo_location }}</td>
                  <td class="px-4 py-2 border-b">{{ row.click_timestamp }}</td>
                  <td class="px-4 py-2 border-b">{{ row.device_type }}</td>
                  <td class="px-4 py-2 border-b">{{ row.os }}</td>
                  <td class="px-4 py-2 border-b">{{ row.browser }}</td>
                  <td class="px-4 py-2 border-b">{{ row.ip_address }}</td>
                </tr>
                </tbody>
              </table>

            </div>
            <PaginationComponent
                :currentPage="currentPage"
                :totalPages="totalPages"
                @update:currentPage="currentPage = $event"
            />
          </div>

          <!-- Close Button -->
          <div class="mt-6 flex justify-end">
            <button
                @click="isOpen = false"
                class="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </transition>


  </DashboardLayout>
</template>

<script>
import { defineComponent } from "vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";
import SearchComponent from "@/components/Fields/SearchComponent.vue";

export default defineComponent({
  components: {SearchComponent, DashboardLayout, PaginationComponent },
  data() {
    return {
      currentPage: 1,
      searchQuery: "",
      currentPageOut: 1,
      recordsPerPage: 5,
      recordsPerPageOut:10,
      trackingIds: [],
      role: this.$usergroup,
      selectedCampaign: [],
      metadata: {
        tracking_id: " ",
        redirect_url: " ",
      }, // Proper initialization
      isOpen: false,
    };
  },
  methods: {
    async getCampaigns() {
      try {
        const response = await this.$axios.request({
          url: "/campaign",
          method: "get",
        });
        this.trackingIds = response.data.tracking_ids || [];
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    },
    async viewCampaign(trackingId) {
      try {
        const response = await this.$axios.request({
          url: `/campaign/view/${trackingId}`,
          method: "get",
        });
        if (response.status === 200) {
          this.selectedCampaign = response.data.tracking_data || {};
          this.metadata = response.data.metadata || {};
          this.isOpen = true;
          this.currentPage = 1;
        }
      } catch (error) {
        console.error("Error fetching campaign details:", error);
      }
    },
  },
  computed: {
    totalPages() {
      if(this.selectedCampaign.length === 0) return 1;
      return this.selectedCampaign.length > 0
          ? Math.ceil(this.selectedCampaign.length / this.recordsPerPage)
          : 1;
    },
    totalPagesOut(){
      if(this.filteredCampaigns.length === 0) return 1;
      return this.filteredCampaigns.length > 0
          ? Math.ceil(this.filteredCampaigns.length / this.recordsPerPageOut)
          : 1;
    },
    paginatedCampaigns(){
      const start = (this.currentPageOut - 1) * this.recordsPerPageOut;
      return this.filteredCampaigns.slice(start, start + this.recordsPerPageOut);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.recordsPerPage;
      const end = start + this.recordsPerPage;
      return this.selectedCampaign.slice(start, end);
    },
    filteredCampaigns() {
      return this.trackingIds.filter((campaign) => {
        return campaign.campaign_name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  mounted() {
    this.getCampaigns();
  },
});
</script>

<style scoped>
</style>

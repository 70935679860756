<template>


    <slot></slot>

</template>

<script>


export default {

}
</script>

<style scoped>
</style>

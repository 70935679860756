<template>
  <div class="sticky bottom-0 p-4 border-t border-gray-700 flex items-center justify-between">
    <!-- User Info -->
    <div class="flex items-center">
      <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4YreOWfDX3kK-QLAbAL4ufCPc84ol2MA8Xg&s"
          alt="User Avatar"
          class="w-10 h-10 rounded-full mr-3"
      />
      <div v-if="!isCollapsed">
        <span class="text-sm font-medium text-orange-500">{{ user.firstName }} {{ user.lastName }}</span>
        <br />
        <span class="text-xs text-white">Last login: {{ user.lastLogin }}</span>
      </div>
    </div>

    <!-- Logout Button -->
    <button
        @click="logoutModalAction"
        class="ml-4 pb-0.5 material-icons text-orange-500"
    >
      power_settings_new
    </button>

    <ConfirmationModalComponent
        title="Logout Confirmation"
        message="Are you sure you want to Logout?"
        :isOpen="logoutModal"
        @confirm="logout"
        @close="logoutModal = false"
    />
  </div>
</template>

<script>
import ConfirmationModalComponent from "@/components/Fields/ConfirmationModalComponent.vue";

export default {
  components: { ConfirmationModalComponent },
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        lastLogin: "2021-01-01 12:00:00"
      },
      logoutModal: false,
      userLoaded: false, // Flag to track request status
    };
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: true
    },
  },
  methods: {
    logoutModalAction() {
      this.logoutModal = true;
    },
    logout() {
      this.logoutModal = false;
      localStorage.removeItem('token');
      this.$router.push('/login');
    },
    getUserLoggedIn()
    {
      let config = this.$config;
      if(config.user){
        this.user.firstName = config.user.first_name;
        this.user.lastName = config.user.last_name;
        this.user.lastLogin = config.user.last_login;
      }
    },
    // async getUser() {
    //   if (this.userLoaded) return; // Prevent multiple calls
    //   this.userLoaded = true;
    //
    //   try {
    //     const response = await this.$axios.get("/user");
    //     const user = response.data.data;
    //     this.user.firstName = user.first_name;
    //     this.user.lastName = user.last_name;
    //     this.user.lastLogin = user.last_login;
    //   } catch (error) {
    //     console.error("Error fetching user data:", error);
    //   }
    // }
  },
  mounted() {
    this.getUserLoggedIn();
    //this.getUser();
  }
};
</script>

<style scoped>
.material-icons {
  font-family: 'Material Icons', serif;
  font-size: 24px;
}
</style>

<template>
  <DashboardLayout>
    <div class="flex justify-center items-center mt-6">
      <div class="container max-w-6xl bg-[#eeeeee] text-black p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Affiliate Statistics</h2>

        <!-- Statistics Overview with Counter Style -->
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
          <div class="bg-orange-500 text-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow">
            <p class="text-lg font-semibold">Total Affiliates</p>
            <p class="text-2xl">{{ totalAffiliates }}</p>
          </div>
          <div class="bg-orange-500 text-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow">
            <p class="text-lg font-semibold">Active Affiliates</p>
            <p class="text-2xl">{{ activeAffiliates }}</p>
          </div>
          <div class="bg-orange-500 text-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow">
            <p class="text-lg font-semibold">Pending Affiliates</p>
            <p class="text-2xl">{{ pendingAffiliates }}</p>
          </div>
        </div>

        <!-- Affiliates Stats Table -->
        <div class="flex flex-col">
          <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
            <div class="flex-1 font-semibold">Affiliate Name</div>
            <div class="flex-1 font-semibold">Total Earnings</div>
            <div class="flex-1 font-semibold">Total Clicks</div>
            <div class="flex-1 font-semibold">Active Status</div>
          </div>
          <div class="flex flex-col">
            <div
                v-for="affiliate in affiliatesStats"
                :key="affiliate.id"
                class="flex justify-between items-center p-3 border-b border-gray-300 hover:bg-gray-200"
            >
              <div class="flex-1">{{ affiliate.name }}</div>
              <div class="flex-1">${{ affiliate.totalEarnings.toFixed(2) }}</div>
              <div class="flex-1">{{ affiliate.totalClicks }}</div>
              <div class="flex-1">
                <span :class="affiliate.active ? 'text-green-500' : 'text-red-500'">
                  {{ affiliate.active ? 'Active' : 'Inactive' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: {DashboardLayout},
  data() {
    return {
      affiliatesStats: [
        { id: 1, name: "John Doe", totalEarnings: 1500.75, totalClicks: 300, active: true },
        { id: 2, name: "Jane Smith", totalEarnings: 800.00, totalClicks: 150, active: false },
        { id: 3, name: "Robert Johnson", totalEarnings: 1200.50, totalClicks: 250, active: true },
        // Add more sample affiliate statistics as needed
      ],
    };
  },
  computed: {
    totalAffiliates() {
      return this.affiliatesStats.length;
    },
    activeAffiliates() {
      return this.affiliatesStats.filter(affiliate => affiliate.active).length;
    },
    pendingAffiliates() {
      return this.affiliatesStats.length - this.activeAffiliates; // Assuming inactive means pending
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto; /* Adds vertical scrolling within the container */
}
</style>

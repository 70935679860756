<template>
  <DashboardLayout>
    <div class="flex justify-center items-center mt-6">
      <div class="container max-w-6xl bg-[#eeeeee] text-black p-6 shadow-md rounded-lg overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">Manage Affiliates</h2>


        <h3 class="text-xl text-orange-500 font-semibold mt-6">Assign New Affiliate to Client/Brand</h3>
        <form @submit.prevent="handleAddAffiliate" class="mt-4 flex flex-col">
          <div class="flex flex-wrap gap-4 mb-4">

            <div class="flex-1 min-w-[calc(50%-0.5rem)]">
              <select v-model="newAffiliate.client" @change="handleClientChange" class="p-3 w-full rounded border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500">
                <option value="" disabled>Select Client</option>
                <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.email }}</option>
              </select>
            </div>
            <div class="flex-1 min-w-[calc(50%-0.5rem)]">
              <select v-model="newAffiliate.brand" class="p-3 w-full rounded border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500">
                <option value="" disabled>Select Brand</option>
                <option v-for="brand in filteredBrands" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
              </select>
            </div>
          </div>

          <div class="mb-4">
            <select v-model="newAffiliate.affiliate" class="p-3 w-full rounded border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500">
              <option value="" disabled>Select Affiliate</option>
              <option v-for="affiliate in affiliates" :key="affiliate.id" :value="affiliate.id">{{ affiliate.name }}</option>
            </select>
          </div>

          <div class="flex justify-center mt-4 mb-4 ">
            <ButtonComponent type="submit" class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800">
              Add Affiliate
            </ButtonComponent>
          </div>
        </form>

        <div class="flex flex-col">
          <SearchComponent
              :value="searchQuery"
              @update:value="searchQuery = $event"
              v-model="searchQuery"
              placeholder="Search By Affiliate Name"
          />
          <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
            <div class="flex-1 font-semibold">Affiliate Name</div>
            <div class="flex-1 font-semibold">Affiliate Email</div>
            <div class="flex-1 font-semibold">Client</div>
            <div class="flex-1 font-semibold">Brand</div>
            <div class="flex-1 font-semibold">*Status*</div>
            <div class="flex-1 font-semibold">Actions</div>
          </div>
          <div class="flex flex-col">
            <div
                v-for="affiliate in paginatedAffiliates"
                :key="affiliate.id"
                class="flex justify-between items-center p-3 border-b border-gray-300 hover:bg-gray-200"
            >
              <div class="flex-1">{{ affiliate.name }}</div>
              <div class="flex-1">{{ affiliate.email }}</div>
              <div class="flex-1">{{ affiliate.client }}</div>
              <div class="flex-1">{{ affiliate.brand }}</div>
              <div class="flex-1">
                <span :class="affiliate.active ? 'text-green-500' : 'text-red-500'">
                  {{ affiliate.active ? 'Active' : 'Inactive' }}
                </span>
              </div>
              <div class="flex-1 flex justify-center">
                <button  @click="deleteConfirmation(affiliate.id)"  class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4 bg-orange-500 text-white px-2 py-1 rounded hover:bg-orange-600 mx-2">
                  <span class="material-icons">delete</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <PaginationComponent
          :currentPage="currentPage"
          :totalPages="totalPages"
          @update:currentPage="currentPage = $event"
        />


      </div>
    </div>

    <ConfirmationModalComponent
        title="Delete Confirmation"
        message="Are you sure you want to delete this connection?"
        :isOpen="deleteModal"
        @confirm="deleteAffiliate"
        @close="deleteModal = false"
    />
  </DashboardLayout>


</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import ConfirmationModalComponent from "@/components/Fields/ConfirmationModalComponent.vue";
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";
import SearchComponent from "@/components/Fields/SearchComponent.vue";

export default {
  components: {SearchComponent, PaginationComponent, DashboardLayout, ButtonComponent, ConfirmationModalComponent },
  data() {
    return {
      deleteModal: false,
      currentPage:1,
      searchQuery: "",
      recordsPerPage: 5,
      affiliateToDelete: null,
      assignedAffiliates: [],
      affiliates: [],
      clients: [],
      brands: [],
      filteredBrands: [],
      newAffiliate: {
        client: "",
        brand: "",
        affiliate: ""
      },
      showEditModal: false,
      editingAffiliate: {
        id: null,
        name: "",
        email: "",
        brandId: ""
      }
    };
  },
  methods: {
    deleteConfirmation(id) {
      this.deleteModal = true;
      this.affiliateToDelete = id;  // Store the ID of the affiliate to be deleted
    },
    getBrandName(brandId) {
      const brand = this.brands.find(b => b.id === brandId);
      return brand ? brand.name : "Unknown";
    },
    getClientName(clientId) {
      const client = this.clients.find(c => c.id === clientId);
      return client ? client.email : "Unknown";
    },
    getAffiliateEmail(affiliateId) {
      const affiliate = this.affiliates.find(a => a.id === affiliateId);
      return affiliate ? affiliate.email : "Unknown";
    },
    toggleStatus(id) {
      const affiliate = this.affiliates.find(a => a.id === id);
      if (affiliate) {
        affiliate.active = !affiliate.active;
      }
    },
    async fetchAffiliates() {
      try {
        const response = await this.$axios.request({
          method: 'get',
          url: '/affiliates/clients'
        });

        const aff_response = await this.$axios.request({
          method: 'get',
          url: '/affiliates/manage'
        });

        if(aff_response.status === 200)
        {
          this.affiliates = aff_response.data.users.map(aff => ({
            id: aff.id,
            name: `${aff.first_name} ${aff.last_name}`,
            email: aff.email,
            active: aff.status_id
          }));
        }


        if (response.status === 200 && response.data) {
          this.assignedAffiliates = response.data.results.map(affiliate => ({
            id: affiliate.id,
            name: affiliate.affiliate_name,
            email: affiliate.affiliate_email,
            client: affiliate.client,
            brand: affiliate.brand,
            brand_id: affiliate.brand_id,
            active: affiliate.status
          }));


          this.brands = response.data.brands;

        }


        console.log('Assigned Affiliates:', this.assignedAffiliates);
      } catch (error) {
        console.error('Error fetching affiliates:', error);
      }
    },
    async fetchClients(){
      try{
        const response = await this.$axios.request({
          method: 'get',
          url: '/clients'
        });
        if(response.status === 200){
          this.clients = response.data.map(client => ({
            id: client.id,
            email: client.email
          }));
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    },
    removeAffiliate(id) {
      this.affiliates = this.affiliates.filter(a => a.id !== id);
    },
    async handleClientChange() {
      if (this.newAffiliate.client) {
        console.log('Client ID:', this.newAffiliate.client);
        try {
          const response = await this.$axios.request({
            method: 'get',
            url: `/brands/id/${this.newAffiliate.client}`
          });


          if (response.status === 200 && response.data) {
            this.filteredBrands = response.data.brands;
          }


        } catch (error) {
          console.error('Error fetching brands:', error);
        }
      }
    },
    async sendNewClientToAffiliate(payload) {
      try {
        const response = await this.$axios.request({
          method: 'POST',
          url: '/affiliates/clients',
          data: payload,
        });
        if (response.status === 200 && response.data) {

          this.$toast.success('Client Assigned to Affiliated Successfully!');
          return response.data;
        }
      } catch (error) {
        console.error('Error sending new client to affiliate:', error);

        return null;
      }
    },
    async handleAddAffiliate() {
      const payload = {
        client_id: this.newAffiliate.client,
        brand_id: this.newAffiliate.brand,
        affiliate_id: this.newAffiliate.affiliate
      };


      const response = await this.sendNewClientToAffiliate(payload);


      if (response) {

        const newId = this.affiliates.length ? Math.max(this.affiliates.map(a => a.id)) + 1 : 1;


        console.log('Before update: Assigned Affiliates', this.assignedAffiliates);


        const selectedAffiliate = this.affiliates.find(affiliate => affiliate.id === this.newAffiliate.affiliate);
        console.log('Selected Affiliate:', this.newAffiliate);
        this.assignedAffiliates = [
          ...this.assignedAffiliates,
          {
            id: newId,
            name: selectedAffiliate ? selectedAffiliate.name : 'Unknown',
            email: selectedAffiliate.email ? selectedAffiliate.email : 'Unknown',
            brand: this.getBrandName(this.newAffiliate.brand),
            client: this.getClientName(this.newAffiliate.client),
            active: selectedAffiliate ? selectedAffiliate.active : true
          }
        ];
        this.newAffiliate = {
          client: "",
          brand: "",
          affiliate: ""
        };
        console.log('After update: Assigned Affiliates', this.assignedAffiliates);
      }
    },
    openEditModal(affiliate) {
      this.editingAffiliate = { ...affiliate };
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.editingAffiliate = null;
    },
    updateAffiliate() {
      const index = this.affiliates.findIndex(a => a.id === this.editingAffiliate.id);
      if (index !== -1) {
        this.affiliates[index] = { ...this.editingAffiliate };
      }
      console.log('Edited Affiliate:', this.editingAffiliate);
      this.closeEditModal();
    },
    async deleteAffiliate() {
      const id = this.affiliateToDelete;  // Use the stored affiliate ID
      try {
        const response = await this.$axios.request({
          method: 'delete',
          url: `/affiliates/delete/${id}`,
        });

        if (response.status === 200) {
          // Remove affiliate from lists after successful deletion
          this.assignedAffiliates = this.assignedAffiliates.filter(a => a.id !== id);
          this.affiliates = this.affiliates.filter(a => a.id !== id);
          this.$toast.success('Affiliate deleted successfully!');
        } else {
          this.$toast.error('Error deleting affiliate');
        }
      } catch (error) {
        console.error('Error deleting affiliate:', error);
      }
      this.deleteModal = false; // Close the modal after deletion
    },
  },
  mounted() {
    this.fetchAffiliates().then(() => {
      this.fetchClients();

    });
  },
  computed:{
    totalPages() {
      if(this.filteredAffiliates.length === 0) return 1;
      return Math.ceil(this.filteredAffiliates.length / this.recordsPerPage);
    },
    paginatedAffiliates() {
      const start = (this.currentPage - 1) * this.recordsPerPage;
      return this.filteredAffiliates.slice(start, start + this.recordsPerPage);
    },
    filteredAffiliates(){
      return this.assignedAffiliates.filter(affiliate => {
        return affiliate.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    }
  }
};

</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
  background-color: #eeeeee;
  overflow-y: auto;
}

input:focus {
  border-color: #f97316;
}

select:focus {
  border-color: #f97316;
}
</style>

<template>
  <DashboardLayout>
  <div>
    <h1>Payouts</h1>
    <p>View Payout Page</p>
  </div>
  </DashboardLayout>
</template>

<script>

import {defineComponent} from "vue";
import DashboardLayout from "@/views/DashboardLayout.vue";

export default defineComponent({
  components: {DashboardLayout}
})
</script>


<style scoped>
</style>
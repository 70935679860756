<template>
  <div class="language-selector flex items-center space-x-4">
    <div
        v-for="lang in languages"
        :key="lang.code"
        class="language-option flex items-center space-x-2 cursor-pointer transition-transform transform hover:scale-110"
        @click="changeLanguage(lang.code)"
        :class="{ active: currentLocale === lang.code }"
    >
      <img
          :src="lang.flag"
          :alt="lang.name + ' flag'"
          class="w-8 h-8 rounded-full border"
      />
      <span class="text-sm font-medium text-white hover:text-gray-900">
        {{ lang.name }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      languages: [
        {code: 'en', name: 'English', flag: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1920px-Flag_of_the_United_States.svg.png'},
        {code: 'el', name: 'Greek', flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Greece.svg/1920px-Flag_of_Greece.svg.png'},
      ],
      currentLocale: this.$i18n.locale,
    };
  },
  methods: {
    changeLanguage(code) {
      console.log("Changing language to:", code);
      console.log("Current i18n object:", this.$i18n);
      this.$i18n.locale = code;
      this.currentLocale = code;
    },
  },
};
</script>

<style scoped>
.language-selector {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  background: rgb(45, 45, 45);
  border-radius: 8px;
}

.language-option {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.language-option img {
  transition: box-shadow 0.3s ease;
}

.language-option.active {
  background: rgb(45, 45, 45);
  color: white;
}

.language-option.active img {
  box-shadow: 0 0 10px #4caf50;
}

.language-option span {
  transition: color 0.3s ease;
}

.language-option:hover span {
  color: #f16f24;
}
</style>

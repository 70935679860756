1<template>
  <DashboardLayout>
    <div class="min-h-screen bg-gray-100 flex items-center justify-center">
      <div class="bg-white shadow-md rounded-lg max-w-4xl w-full p-6">
        <!-- Header -->
        <div class="flex justify-between items-center border-b pb-4 mb-6">
          <h1 class="text-2xl font-bold text-gray-700">My Profile</h1>
        </div>
        <!-- Tabs Navigation -->
        <div class="flex space-x-4 border-b pb-2">
          <button
              v-for="(tab, index) in tabs"
              :key="index"
              @click="handleTabChange(index)"
              :class="{
            'text-orange-500 border-b-2 border-orange-500': activeTab === index,
            'text-gray-600': activeTab !== index,
          }"
              class="pb-2 text-lg font-semibold transition hover:text-orange-500 focus:outline-none"
          >
            {{ tab }}
          </button>
        </div>
        <!-- Tabs Content -->
        <div class="mt-6">
          <!-- Profile Tab -->
          <div v-show="activeTab === 0">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <!-- Left Column: Avatar Section -->
              <div class="flex flex-col items-center border-r border-gray-300 md:pr-6">
                <div >
                  <img
                      :src="user.avatar"
                      alt="Profile Picture"
                      class="w-32 h-32 rounded-full border-4 border-gray-200"
                  />
<!--                  <button @click="changeAvatar" class="absolute bottom-0 right-0 bg-white rounded-full p-2 shadow-md">-->
<!--                    <span class="text-xs text-gray-700">Change</span>-->
<!--                  </button>-->
                </div>
                <h2 class="text-xl font-semibold mt-4 text-gray-700">{{ user.name }}</h2>
                <p class="text-gray-500">{{ user.email }}</p>
              </div>

              <!-- Right Column: Profile Form -->
              <div class="md:pl-6">
                <form @submit.prevent="">
                  <!-- Name -->
                  <div class="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <label for="first_name" class="block text-sm font-medium text-gray-700">
                        First Name
                      </label>
                      <input
                          id="first_name"
                          v-model="user.first_name"
                          type="text"
                          class="mt-1 p-2 w-full border rounded-md focus:ring-orange-500 focus:border-orange-500"
                           :disabled="true"
                      />
                    </div>
                    <div>
                      <label for="last_name" class="block text-sm font-medium text-gray-700">
                        Last Name
                      </label>
                      <input
                          id="last_name"
                          v-model="user.last_name"
                          type="text"
                          class="mt-1 p-2 w-full border rounded-md focus:ring-orange-500 focus:border-orange-500"
                           :disabled="true"
                      />
                    </div>
                  </div>


                  <div class="mb-4">
                    <label for="address" class="block text-sm font-medium text-gray-700">
                      Address
                    </label>
                    <input
                        id="address"
                        v-model="user.address"
                        type="text"
                        class="mt-1 p-2 w-full border rounded-md focus:ring-orange-500 focus:border-orange-500"
                         :disabled="true"
                    />
                  </div>

                  <!-- City & Postal Code -->
                  <div class="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <label for="city" class="block text-sm font-medium text-gray-700">
                        City
                      </label>
                      <input
                          id="city"
                          v-model="user.city"
                          type="text"
                          class="mt-1 p-2 w-full border rounded-md focus:ring-orange-500 focus:border-orange-500"
                           :disabled="true"
                      />
                    </div>
                    <div>
                      <label for="postal_code" class="block text-sm font-medium text-gray-700">
                        Postal Code
                      </label>
                      <input
                          id="postal_code"
                          v-model="user.postal_code"
                          type="text"
                          class="mt-1 p-2 w-full border rounded-md focus:ring-orange-500 focus:border-orange-500"
                           :disabled="true"
                      />
                    </div>
                  </div>

                  <!-- Company Name & Website URL -->
                  <div class="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <label for="company_name" class="block text-sm font-medium text-gray-700">
                        Company Name
                      </label>
                      <input
                          id="company_name"
                          v-model="user.company_name"
                          type="text"
                          class="mt-1 p-2 w-full border rounded-md focus:ring-orange-500 focus:border-orange-500"
                           :disabled="true"
                      />
                    </div>
                    <div>
                      <label for="website_url" class="block text-sm font-medium text-gray-700">
                        Website URL
                      </label>
                      <input
                          id="website_url"
                          v-model="user.website_url"
                          type="url"
                          class="mt-1 p-2 w-full border rounded-md focus:ring-orange-500 focus:border-orange-500"
                           :disabled="true"
                      />
                    </div>
                  </div>

                  <!-- Email & Phone -->
                  <div class="mb-4">
                    <div>
                      <label for="email" class="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                          id="email"
                          v-model="user.email"
                          type="email"
                          class="mt-1 p-2 w-full border rounded-md focus:ring-orange-500 focus:border-orange-500"
                           :disabled="true"
                      />
                    </div>
                  </div>

                  <!-- User Group -->
                  <div class="mb-4">
                    <label for="group" class="block text-sm font-medium text-gray-700">
                      User Group
                    </label>
                    <div class="mt-1 p-2 w-full border rounded-md">
                      <template v-if="Array.isArray(user.group)">
                        <div v-for="(group, index) in user.group" :key="index">
                          <span class="px-2 py-1 bg-admin-200 text-gray-700 rounded-md mr-2">
                            {{ group }}
                          </span>
                        </div>
                      </template>
                      <template v-else>
                        <span class="px-2 py-1 bg-admin-200 text-gray-700 rounded-md">
                          {{ user.usergroup }}
                        </span>
                      </template>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>

          <!-- Account Tab -->
          <div v-show="activeTab === 1">
            <h2 class="text-xl font-bold mb-4">Account Settings</h2>
            <p class="text-gray-600">
              Manage your account settings like changing your password, setting up two-factor authentication, and more.
            </p>
            <div class="mt-4">
              <div>
                <label for="username" class="block">Current Password:</label>
                <input
                    type="password"
                    v-model="account.currentPassword"
                    id="current_password"
                    class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="mt-4">
              <div>
                <label for="password" class="block">Password:</label>
                <input
                    type="password"
                    id="password"
                    v-model="account.newPassword"
                    class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                />
              </div>
              <div class="mt-4">
                <label for="confirm_password" class="block">Confirm Password:</label>
                <input
                    type="password"
                    id="confirm_password"
                    v-model="account.confirmPassword"
                    class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                />
              </div>
              <div class="mt-4">
                <div class="language-selector flex items-center space-x-4">
                  <div
                      v-for="lang in languages"
                      :key="lang.code"
                      class="language-option flex items-center space-x-2 cursor-pointer transition-transform transform hover:scale-110"
                      @click="changeLanguage(lang.code)"
                      :class="{ active: account.locale === lang.code }"
                  >
                    <img
                        :src="lang.flag"
                        :alt="lang.name + ' flag'"
                        class="w-8 h-8 rounded-full border"
                    />
                    <span class="text-sm font-medium text-admin-200 hover:text-gray-900">
                {{ lang.name }}
              </span>
                  </div>
                </div>
              </div>
              <div class="mt-4 flex justify-end gap-4">
                <button
                    class="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                    @click="changePassword(account.currentPassword, account.newPassword, account.confirmPassword)"
                >
                  Save Changes
                </button>
              </div>





            </div>
              <!-- Placeholder for account 2fa -->
          </div>
          <div v-show="activeTab === 2">
            <h2 class="text-xl font-bold mb-4">Payment Settings</h2>
            <p class="text-gray-600">
              Manage your payment settings like adding a new card, updating your billing address, and more.
            </p>
            <div class="mt-4">
              <div>
                <label for="bank_name" class="block">Bank Name:</label>
                <input
                    type="text"
                    v-model="paymentDetails.bank_name"
                    id="bank_name"
                    class="w-full p-2 mt-1 border border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div class="mt-4">
              <label for="iban" class="block">IBAN:</label>
              <input
                  type="text"
                  v-model="paymentDetails.iban"
                  id="iban"
                  class="w-full p-2 mt-1 border border-gray-300 rounded-md"
              />
            </div>
            <div class="mt-4">
              <label for="swift_code" class="block">SWIFT Code:</label>
              <input
                  type="text"
                  id="swift_code"
                  v-model="paymentDetails.swift_code"
                  class="w-full p-2 mt-1 border border-gray-300 rounded-md"
              >
            </div>
            <div class="mt-4 flex justify-end gap-4">
              <button
                  class="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                  @click="saveProfile()"
              >
                Save Changes
              </button>
          </div>
          </div>



          <div v-show="activeTab === 3 && role === this.$defaultUsergroups.affiliate">
            <h2 class="text-xl font-bold mb-4">Postback Settings</h2>
            <p class="text-gray-600">
              Manage your postback settings like adding a new postback URL, updating your postback method, and more.
            </p>
            </div>

          <ToggleSwitchComponent
              v-show="activeTab === 3"
              v-model="user.postback_enabled"
              label="Enable Postback"
              class="mt-4"
              @blur="savePostbackStatus()"
          />

          <input
            class="w-full p-2 mt-1 border border-gray-300 rounded-md"
            v-show="activeTab === 3 && user.postback_enabled"
            v-model="user.postback_url"
            placeholder="Postback URL"
            @blur="savePostback()"
          />




        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/views/DashboardLayout.vue";

import ToggleSwitchComponent from "@/components/Fields/ToggleSwitchComponent.vue";
export default {
  components: {ToggleSwitchComponent, DashboardLayout },
  data() {
    return {
      activeTab: 0,
      role: this.$usergroup,
      tabs: ["Profile", "Account", "Payment"],

      languages: [
        {code: 'en', name: 'English', flag: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1920px-Flag_of_the_United_States.svg.png'},
        {code: 'el', name: 'Greek', flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Greece.svg/1920px-Flag_of_Greece.svg.png'},
      ],
      currentLocale: 'en',
      account: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        locale: 'en',
      },
      paymentDetails: {
        bank_name: '',
        iban: '',
        swift_code: '',
      },
      user: {
        avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4YreOWfDX3kK-QLAbAL4ufCPc84ol2MA8Xg&s",
        first_name: "John",
        last_name: "Doe",
        address: "123 Main St",
        city: "New York",
        postal_code: "10001",
        company_name: "Acme Inc",
        website_url: "https://acme.com",
        email: "john.doe@example.com",
        phone: "123-456-7890",
        postback: false,
        postback_url: "",
        usergroup: 'Admin',
        notifications: {
          email: true,
          sms: false,
        },
        preferences: {
          theme: "light",
          language:"en",
        }
      },

    };
  },
  methods: {
    async savePostback(){
      let response = await this.$axios.request(
          {
            url: '/profile/postback',
            method: 'POST',
            data: {
              url: this.user.postback_url
            }
          }
      )

      if(response.status === 200) this.$toast.success('Postback URL saved successfully');
      else this.$toast.error('Failed to save postback URL');
    },
    async saveProfile() {
      const data = {};
      data.paymentDetails = this.paymentDetails;


      let response = await this.$axios.request({
        url: '/profile/id/',  // Replace with the correct endpoint per tab
        method: 'PUT',
        data: data
      });

      if (response.data.status === 'success') {
        return this.$toast.success(response.data.message);
      }
      return this.$toast.error(response.data.message);
    },
    async changeLanguage(code) {
      this.$i18n.locale = code;
      this.account.locale = code;


      let response = await this.sendNewLocale(code);

      if(response.status === 200) this.$toast.success('Language changed successfully');
      else this.$toast.error('Failed to change language');


    },
    async sendNewLocale(code) {
      return await this.$axios.request({
        url: '/profile/locale',
        method: 'POST',
        data: {
          locale: code
        }
      });



    },
    changeAvatar() {
      console.log("Change avatar clicked");
      // Logic for changing the avatar
    },
    async getProfile() {
      if (this.activeTab === 0) {
        let response = await this.$axios.request({
          url: '/profile',
          method: 'GET'
        });

        console.log(response.data);
        this.user = response.data;
        this.account.locale = response.data.locale;
        this.i18n.locale = response.data.locale;
        this.currentLocale = response.data.locale;
        this.user.postback_url = response.data.postback_url;
        this.user.avatar = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4YreOWfDX3kK-QLAbAL4ufCPc84ol2MA8Xg&s";
      } else if (this.activeTab === 1) {
        // Handle account details fetching if needed
      } else if (this.activeTab === 2) {
        // Fetch payment details
      }
    },
    async handleTabChange(index) {
      this.activeTab = index;
      await this.getProfile();
    },
    async savePostbackStatus() {
      let response = await this.$axios.request({
        url: '/profile/postback',
        method: 'POST',
        data: {
          postback_enabled: this.user.postback_enabled
        }
      });

      if (response.status === 200) {
        return this.$toast.success('Postback status saved successfully');
      }
      return this.$toast.error('Failed to save postback status');
    },
    async changePassword(currentPassword, newPassword, confirmPassword) {
      let response = await this.$axios.request({
        url: '/user/change_password',
        method: 'POST',
        data: {
          current_password: currentPassword,
          password: newPassword,
          confirm_password: confirmPassword
        }
      });

      if (response.data.status === 'success') {
        return this.$toast.success(response.data.message);
      }
      return this.$toast.error(response.data.message);
    },


  },
  mounted(){
    this.getProfile();
    if (this.role === this.$defaultUsergroups.affiliate) {
      this.tabs.push("Postback");
    }

  }
};
</script>
<style scoped>
/* Language Selector Start */

.language-selector {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  border-radius: 8px;
}

.language-option {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.language-option img {
  transition: box-shadow 0.3s ease;
}

.language-option.active {
  color: white;
}

.language-option.active img {
  box-shadow: 0 0 10px #4caf50;
}

.language-option span {
  transition: color 0.3s ease;
}

.language-option:hover span {
  color: #f16f24;
}

/* Language Selector End */

</style>
<template>
  <div class="flex justify-center items-center mt-4">
    <button
        class="px-4 py-2 mx-1 bg-orange-500 text-white rounded hover:bg-orange-600"
        :disabled="currentPage === 1"
        @click="goToPage(currentPage - 1)"
    >
      Previous
    </button>
    <span class="mx-2">{{ currentPage }} / {{ totalPages }}</span>
    <button
        class="px-4 py-2 mx-1 bg-orange-500 text-white rounded hover:bg-orange-600"
        :disabled="currentPage === totalPages"
        @click="goToPage(currentPage + 1)"
    >
      Next
    </button>
  </div>
</template>

<script>
/**
 * <PaginationComponent
 *   :currentPage="currentPage"
 *   :totalPages="totalPages"
 *   @update:currentPage="currentPage = $event"
 *    />
 *    At computed:
 *    totalPages() {
 *       return Math.ceil(this.filteredUsers.length / this.recordsPerPage);
 *     },
 *     paginatedUsers() {
 *       const start = (this.currentPage - 1) * this.recordsPerPage;
 *       return this.filteredUsers.slice(start, start + this.recordsPerPage);
 *     }
 *
 *
 *
 *
 */


export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
      default: 1
    },
    totalPages: {
      type: Number,
      required: true,
      default: 1
    }
  },
  methods: {
    goToPage(page) {
      // Ensure page stays within bounds
      if (page < 1) {
        page = 1;
      }
      if (page > this.totalPages) {
        page = this.totalPages;
      }

      // Emit the updated page number
      this.$emit('update:currentPage', page);
    }
  }
};
</script>

import axios from "@/module/axios";

export class ReactiveModel
{
    constructor() {
        this.lastServerState = {};
        this.startAuthCheck();
        this.axios = axios;
    }

    async getUser(){
        try {
            const response = await axios.request({
                url: '/user', method: 'GET'
            });
            return response.data;
        } catch (error) {
            return null;
        }
    }





    async checkAuthStatus() {
        const token = localStorage.getItem('token') || null ;

        if (token === null) {
            return false;
        }

        try {
            const response = await axios.request({url: '/auth', method: 'POST' });
            return response.data.status === 'success';

        } catch (error) {
            return false;
        }
    }



    startAuthCheck() {
        setInterval(async () => {
            const isAuthenticated = await this.checkAuthStatus();
            if (!isAuthenticated) {
                window.location.href = '/login';
            }
        }, 60000 * 5); // 60000 milliseconds = 1 minute
    }



    // create a reactive model dashboard and add attributes to it
    dashboard = {
        notifications: {},
        inbox: {},
        overview:{},
        users: {},
        affiliates: {},
        media: {},
        commission:{},
        sources: {},
        products: {},
        settings: {},
    }

}

export default new ReactiveModel();
<template>
  <div>
    <label class="block text-sm font-medium text-gray-300 mt-4">{{ label }}</label>
    <input
      :type="type"
      v-model="inputValue"
      :required="required"
      :placeholder="placeholder"
      class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-white sm:text-sm mt-2"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value); // Emit input event to update v-model in parent
      }
    }
  }
}
</script>

<style scoped>
/* You can add additional styles if needed */
</style>

<template>
  <div class="flex min-h-screen">
    <SidebarComponent />
    <div class="flex flex-col w-full">
      <TopBarComponent />
      <div class="main-content flex-1">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import TopBarComponent from "@/components/Admin/TopBar/TopBarComponent.vue";
import SidebarComponent from "@/components/Admin/SidebarComponent.vue";

export default {
  components: {
    TopBarComponent,
    SidebarComponent,
  },
};
</script>

<style scoped>
.sidebar {
  transition: width 0.3s ease;
}

.main-content {
  flex-grow: 1;

}

/* Adjust TopBar when sidebar is collapsed */
.topbar {
  transition: margin-left 0.3s ease;
}

.flex {
  display: flex;
}

.min-h-screen {
  min-height: 100vh;
}

.w-full {
  width: 100%;
}

/* Responsiveness: when sidebar collapses, make the topbar span across the full width */
@media (max-width: 1024px) {
  .topbar {
    margin-left: 0;
  }

  .sidebar {
    width: 0;
  }
}
</style>

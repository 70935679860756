<template>
  <DashboardLayout>
    <div class="flex flex-col h-screen">
      <div class="flex-1 flex flex-col">
        <div class="flex-1">
          <div class="container max-w-3xl mx-auto p-4 bg-admin-100 text-black rounded-lg shadow-md overflow-y-auto">
            <div class="flex items-center justify-between">
              <h2 class="text-2xl font-bold mx-auto mb-4 text-orange-500">{{ $translate('commission.manage_contracts.title')}}</h2>
            </div>
            <SearchComponent
                :value="searchQuery"
                @update:value="searchQuery = $event"
                v-model="searchQuery"
                :placeholder="$translate('placeholders.search.by_name')"
            />
              <div class="flex flex-col bg-gray-200 rounded-lg">
                <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
                  <div class="py-3 px-4 font-semibold flex-1">{{ $translate('commission.manage_contracts.contract_name')}}</div>
                  <div v-show="this.role === this.$defaultUsergroups.client || this.role === this.$defaultUsergroups.superadmin " class="py-3 px-4 font-semibold flex-1">{{$translate('affiliate')}}</div>
                  <div v-show="this.role === this.$defaultUsergroups.superadmin || this.role === this.$defaultUsergroups.affiliate " class="py-3 px-4 font-semibold flex-1">{{$translate('client')}}</div>
                  <div class="py-3 px-4 font-semibold flex-1">{{ $translate('product') }}</div>
                  <div class="py-3 px-4 font-semibold flex-none">{{$translate('action')}}</div>
                </div>
                <div v-for="contract in paginatedContracts" :key="contract.id" class="flex border-b border-gray-400 hover:bg-gray-300 transition-colors duration-200">
                  <div class="py-3 px-4 flex-1">{{ contract.name }}</div>
                  <div v-show="this.role === this.$defaultUsergroups.client || this.role === this.$defaultUsergroups.superadmin " class="py-3 px-4 flex-1">{{ contract.affiliate }}</div>
                  <div v-show="this.role === this.$defaultUsergroups.superadmin || this.role === this.$defaultUsergroups.affiliate" class="py-3 px-4 flex-1">{{ contract.client }} </div>
                  <div class="py-3 px-4 flex-1">{{ contract.product }}</div>
                  <div class="py-3 px-4 flex-none">
                    <div class="flex justify-center max-w-xs">
                      <ButtonComponent  class="bg-orange-500 text-white px-4 py-1 rounded hover:bg-orange-600 mx-2">
                        <span @click="deleteContractModal(contract.id)" class="material-icons">delete</span>
                      </ButtonComponent>
                    </div>
                  </div>
                </div>
              </div>
            <PaginationComponent
                :currentPage="currentPage"
                :totalPages="totalPages"
                @update:currentPage="currentPage = $event"
            />
          </div>
        </div>
      </div>


      <transition name="fade">
        <div v-if="isConfirmationModalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div class="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
            <h3 class="text-2xl font-semibold text-gray-800 mb-4">{{ $translate('confirm') }}</h3>
            <p class="text-gray-700 mb-4">{{ $translate('are_you_sure') }}</p>
            <div class="flex justify-between">
              <button @click="confirmDeleteContract" class="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600">
                {{ $translate('confirm') }}
              </button>
              <button @click="closeDeleteModal" class="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600">
                {{ $translate('cancel') }}
              </button>
            </div>
          </div>
        </div>
      </transition>

    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import ConfirmationModalComponent from "@/components/Fields/ConfirmationModalComponent.vue";
import SearchComponent from "@/components/Fields/SearchComponent.vue";
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";

export default {
  components: {PaginationComponent, SearchComponent, ConfirmationModalComponent, DashboardLayout, ButtonComponent },
  data() {
    return {
      contracts: [],
      currentPage: 1,
      recordsPerPage: 2,
      searchQuery: '',
      products: [],
      affiliates: [],
      role: this.$usergroup,
      isModalOpen: false,
      isEditMode: false,
      newContract: {
        id: null,
        name: '',
        affiliate: '',
        product: '',
      },
      isConfirmationModalOpen: false,
      deleteModalTitle: '',
      deleteModalMessage: '',
      deleteModalActionLabel: '',
      contractIdToDelete: null,

    };
  },
  methods: {
    editContract(contract) {
      this.isModalOpen = true;
      this.isEditMode = true;
      this.newContract = {...contract};
    },
    async deleteContractModal(contractId) {

      this.isConfirmationModalOpen = true;
      this.contractIdToDelete = contractId;
    },
    async confirmDeleteContract() {
      // Check if contractIdToDelete is set before proceeding
      if (!this.contractIdToDelete) return;

      try {
        let response = await this.$axios.request({
          url: `/contracts/id/${this.contractIdToDelete}`,
          method: 'DELETE',
        });

        if (response.status === 200) {
          this.contracts = this.contracts.filter(contract => contract.id !== this.contractIdToDelete);
          this.$toast.success(this.$translate('commission.manage_contracts.messages.delete_success'));
        } else {
          this.$toast.error(this.$translate('commission.manage_contracts.messages.delete_error'));
        }
      } catch (error) {
        console.error(error);
        this.$toast.error(this.$translate('commission.manage_contracts.messages.delete_error'));
      } finally {
        this.closeDeleteModal();
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.resetForm();
    },
    closeDeleteModal() {
      this.isConfirmationModalOpen = false;
      this.bannerIdToDelete = null;
    },
    handleSubmit() {
      if (this.isEditMode) {
        this.updateContract();
      } else {
        this.addContract();
      }
      this.closeModal();
    },
    addContract() {
      this.contracts.push({
        id: this.contracts.length + 1,
        name: this.newContract.name,
        affiliate: {name: this.newContract.affiliate},
        product: {name: this.newContract.product},
      });
    },
    async updateContract() {
      let response = await this.$axios.request({
          url: `/contracts/id/${this.newContract.id}`,
          method: 'PUT',
          data: this.newContract
      });

      if(response.status === 200){
        this.$toast.success(this.$translate('commission.manage_contracts.messages.success'));


        const index = this.contracts.findIndex(contract => contract.id === this.newContract.id);
        if (index !== -1) {
          this.contracts[index] = {...this.newContract};
        }
        return;
      }
      this.$toast.error(this.$translate('commission.manage_contracts.messages.error'));

    },
    async fetchContracts()
    {
      let response = await this.$axios.request({
        url: '/contracts/id',
        method: 'GET'
      });

      if(this.role === this.$defaultUsergroups.superadmin){

        this.products = response.data.products;
        this.affiliates = response.data.affiliates;
        this.clients = response.data.clients;


        console.log('Clients', this.clients);
        console.log('Contracts', response.data.contracts);



        this.contracts = response.data.contracts.map(
          contract => ({
            id: contract.id,
            name: contract.contract_name,
            affiliate: this.affiliates[contract.affiliate_id].first_name + ' ' + this.affiliates[contract.affiliate_id].last_name,
            product: this.products[contract.product_id].name,
            client: this.clients[contract.client_id].company_name || 'N/A'
          })
        );



      }

      if(this.role === this.$defaultUsergroups.affiliate)
      {
        this.products = response.data.products;
        this.affiliates = response.data.affiliates;
        this.clients = response.data.clients;

        console.log('Contract', response.data.contracts);

        console.log('Clients', this.clients);
        console.log('Clients', typeof this.clients);


        this.contracts = response.data.contracts.map(
          contract => ({
            id: contract.id,
            name: contract.contract_name,
            affiliate: this.affiliates[contract.affiliate_id].first_name + ' ' + this.affiliates[contract.affiliate_id].last_name,
            product: this.products[contract.product_id].name,
            client: this.clients[contract.client_id] ? this.clients[contract.client_id].company_name : 'N/A'
          })
        );


      }


      if(this.role === this.$defaultUsergroups.client) {
        this.products = response.data.products;
        this.affiliates = response.data.affiliates;
        this.clients = response.data.clients;



        this.contracts = response.data.contracts.map(
            contract => ({
              id: contract.id,
              name: contract.contract_name,
              affiliate: this.affiliates[contract.affiliate_id].first_name + ' ' + this.affiliates[contract.affiliate_id].last_name,
              product: this.products[contract.product_id].name,
            })
        );

      }



    },
    resetForm() {
      this.newContract = {id: null, name: '', affiliate: '', product: ''};
    },
  },
  mounted() {
    this.fetchContracts();
  },
  computed: {
    filteredContracts() {
      return this.contracts.filter(contract => {
        return contract.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
    totalPages(){
      if(this.filteredContracts.length === 0) return 1;
      return Math.ceil(this.filteredContracts.length / this.recordsPerPage);
    },
    paginatedContracts(){
      const start = (this.currentPage - 1) * this.recordsPerPage;
      return this.filteredContracts.slice(start, start + this.recordsPerPage);
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
}
</style>

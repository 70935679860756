<template>
  <DashboardLayout>
    <div class="flex justify-center rounded-2xl items-center shadow-md  bg-gray-100 max-w-lg mx-auto mt-6">
      <div class="container max-w-lg bg-[#eeeeee] text-black p-6 rounded overflow-y-auto">
        <h2 class="text-2xl text-orange-500 font-bold mb-12 text-center">{{ ($translate('users.create_user.title')) }}</h2>

        <div class="mb-3">
          <!-- Progress Indicator -->
          <div class="flex justify-between items-center mb-4">
            <div class="flex-1 h-2 bg-gray-500 rounded" :class="step >= 1 ? 'bg-orange-500' : ''"></div>
            <div class="flex-1 h-2 bg-gray-500 rounded ml-2" :class="step >= 2 ? 'bg-orange-500' : ''"></div>
            <div class="flex-1 h-2 bg-gray-500 rounded ml-2" :class="step >= 3 ? 'bg-orange-500' : ''"></div>
          </div>
        </div>

        <form v-if="!submitted" @submit.prevent="handleStep" class="space-y-4">
          <div v-if="step === 1">
            <div class="language-selector flex items-center space-x-4">
              <div
                  v-for="lang in languages"
                  :key="lang.code"
                  class="language-option flex items-center space-x-2 cursor-pointer transition-transform hover:scale-110"
                  @click="changeLanguage(lang.code)"
                  :class="{ active: currentLocale === lang.code }"
              >
                <img
                    :src="lang.flag"
                    :alt="lang.name + ' flag'"
                    class="w-8 h-8 rounded-full border"
                />
                <span class="text-sm font-medium text-black hover:text-gray-900">
                {{ lang.name }}
              </span>
              </div>
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="email">{{ ($translate('email')) }}</label>
              <input
                  id="email"
                  v-model="newUser.email"
                  type="text"
                  placeholder="Enter email"
                  autocomplete="email"
                  @blur="checkEmailExistence"
                  class="block w-full px-3 py-2 bg-form-100 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 text-black sm:text-sm mt-2"
                  :class="{
                    'border-red-500 focus:ring-red-500 focus:border-red-500': emailExists,
                    'border-green-500 focus:ring-green-500 focus:border-green-500': !emailExists && email !== ''
                  }"
                  required
              />
              <p v-if="emailExists" class="text-sm text-red-500 mt-2">{{ emailError }}</p>
            </div>



            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="password">{{ ($translate('password')) }}</label>
              <input
                  id="password"
                  v-model="newUser.password"
                  type="password"
                  placeholder="Enter password"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="confirmPassword">{{ ($translate('confirmPassword')) }}</label>
              <input
                  id="confirmPassword"
                  v-model="newUser.confirmPassword"
                  type="password"
                  placeholder="Confirm password"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="group">{{ ($translate('userGroup')) }}</label>
              <select
                  id="group"
                  v-model="newUser.group"
                  class="p-2 rounded bg-form-100 placeholder-gray-400 text-black focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              >
                <option value="">{{ ($translate('select_group')) }}</option>
                <option v-for="group in userGroups" :key="group.id" :value="group.id">{{ group.name }}</option>
              </select>
            </div>
          </div>

          <!-- Step 2 -->
          <div v-if="step === 2">
            <div>
              <label class="block text-admin-200 font-mono" for="firstName">{{ ($translate('firstName')) }}</label>
              <input
                  id="firstName"
                  v-model="newUser.firstName"
                  type="text"
                  placeholder="Enter first name"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="lastName">{{ ($translate('lastName')) }}</label>
              <input
                  id="lastName"
                  v-model="newUser.lastName"
                  type="text"
                  placeholder="Enter last name"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="address">{{ ($translate('address')) }}</label>
              <input
                  id="address"
                  v-model="newUser.address"
                  type="text"
                  placeholder="Enter address"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="city">{{ ($translate('city')) }}</label>
              <input
                  id="city"
                  v-model="newUser.city"
                  type="text"
                  placeholder="Enter city"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="postalCode">{{ ($translate('postalCode')) }}</label>
              <input
                  id="postalCode"
                  v-model="newUser.postalCode"
                  type="text"
                  placeholder="Enter postal code"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <CountrySelectBackComponent
                  v-model="newUser.country"
              />
            </div>
          </div>

          <!-- Step 3 -->
          <div v-if="step === 3">
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="companyName">{{ ($translate('companyName')) }}</label>
              <input
                  id="companyName"
                  v-model="newUser.companyName"
                  type="text"
                  placeholder="Enter company name"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="websiteURL">{{ ($translate('websiteURL')) }}</label>
              <input
                  id="websiteURL"
                  v-model="newUser.websiteURL"
                  type="text"
                  placeholder="Enter website URL"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
            <div>
              <label class="block mb-2 text-admin-200 font-mono" for="brands">{{ ($translate('brands.title')) }}</label>
              <input
                  id="brands"
                  v-model="newUser.brands"
                  type="text"
                  placeholder="Enter brands"
                  class="p-2 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 w-full mb-3"
                  required
              />
            </div>
          </div>

          <!-- Action Buttons -->
          <div class="flex justify-between mt-4">
            <ButtonComponent v-if="step > 1" @click="goBack" class="bg-black text-white hover:bg-white hover:text-black mr-4">{{ ($translate('back')) }}</ButtonComponent>
            <ButtonComponent v-if="step < 3" type="submit" class="bg-orange-500 text-white" >{{ ($translate('next')) }}</ButtonComponent>
            <ButtonComponent v-if="step === 3" type="submit"  class="bg-orange-500 text-white">{{ ($translate('create_user')) }}</ButtonComponent>
          </div>
        </form>

        <!-- Success Page -->
        <div v-if="submitted && success" class="mt-4 text-center">
          <h3 class="text-xl text-green-600 font-bold mb-12">{{ ($translate('messages.user_created_successfully')) }}</h3>
          <p class="mb-4">{{ ($translate('messages.user_has_been_created')) }}</p>
          <ButtonComponent @click="resetForm" class="bg-orange-500 text-white">{{ ($translate('create_another_user')) }}</ButtonComponent>
        </div>

        <!-- Failure Page -->
        <div v-if="submitted && !success" class="mt-4 text-center">
          <h3 class="text-xl text-red-600 font-bold mb-4">{{ ($translate('messages.user_creation_failed')) }}</h3>
          <p class="mb-4">{{ ($translate('messages.error_creating_user')) }}</p>
          <ButtonComponent @click="resetForm" class="bg-orange-500 text-white">{{ ($translate('try_again')) }}</ButtonComponent>
        </div>

        <!-- Error dialog -->
        <ModalDialogComponent
            :title="modalTitle"
            :message="modalMessage"
            :visible="showModal"
            @close="showModal = false"
        />



      </div>



    </div>


  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import ModalDialogComponent from "@/components/Fields/ModalDialogComponent.vue";
import CryptoJS from "crypto-js";
import CountrySelectBackComponent from "@/components/Form/CountrySelectBackComponent.vue";


let secret = "657f8b8da628ef83cf69101b6817150a";
export default {
  components: {CountrySelectBackComponent, ModalDialogComponent, DashboardLayout, ButtonComponent },
  data() {
    return {
      step: 1,
      submitted: false,
      emailExists: false,
      success: false,
      newUser: {
        email: "",
        password: "",
        confirmPassword: "",
        group: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        postalCode: "",
        country: "",
        companyName: "",
        websiteURL: "",
        brands: "",
        email_hash: "",
      },
      languages: [
        {code: 'en', name: 'English', flag: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1920px-Flag_of_the_United_States.svg.png'},
        {code: 'el', name: 'Greek', flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Greece.svg/1920px-Flag_of_Greece.svg.png'},
      ],
      currentLocale: this.$i18n.locale,
      userGroups: [],
      showModal: false,
      modalMessage: '',
      modalTitle: '',
    };
  },
  methods: {
    async getAllUserGroups() {
      try {
        let response = await this.$axios.request({
          url: '/usergroup/id',
          method: 'GET',
        });
        this.userGroups = response.data.user_groups;
        console.log('User groups:', this.userGroups);
      } catch (error) {
        console.error('There was an error fetching user groups:', error);
      }
    },
    isValidEmail(email) {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return regex.test(email);
    },
    handleStep() {
      if (this.step === 1) {
        if(this.emailExists){
          this.$toast.warning(this.$translate('email_exists'));
          return;
        }
        // add validation if password is less than 6 characters
        if (this.newUser.password.length < 8) {
          this.$toast.warning(this.$translate('password_length'));
          return;
        }

        if (!this.isValidEmail(this.newUser.email)) {
          this.$toast.warning(this.$translate('invalid_email'));
          return;
        }
        if (this.newUser.password !== this.newUser.confirmPassword) {
          this.$toast.warning(this.$translate('passwords_not_match'));
          // this.showModal = true;
          // this.modalTitle = "Error";
          // this.modalMessage = "Passwords do not match!";
          return;
        }
        this.step++;
      } else if (this.step === 2) {
        if (!this.newUser.firstName || !this.newUser.lastName || !this.newUser.address || !this.newUser.city || !this.newUser.postalCode || !this.newUser.country) {
          this.$toast.warning(this.$translate('required_fields_missing'));
          return;
        }

        this.step++;
      } else if (this.step === 3) {

        if (!this.newUser.companyName || !this.newUser.websiteURL || !this.newUser.brands) {
          this.$toast.warning(this.$translate('required_fields_missing'));
          return;
        }
        // Step 3: Submit registration
        this.submitForm();
      }
    },
    goBack() {
      if (this.step > 1) {
        --this.step;
      }
    },
    changeLanguage(code) {
      console.log("Changing language to:", code);
      console.log("Current i18n object:", this.$i18n);
      //this.$i18n.locale = code;
      this.currentLocale = code;
      this.locale = code;
    },
    submitForm() {
      console.log('Submitting form:', this.newUser);

      const payload = {
        email: this.newUser.email,
        password: this.newUser.password,
        locale: this.currentLocale,
        usergroup_id: this.newUser.group,
        first_name: this.newUser.firstName,
        last_name: this.newUser.lastName,
        address: this.newUser.address,
        city: this.newUser.city,
        postal_code: this.newUser.postalCode,
        country: this.newUser.country,
        company_name: this.newUser.companyName,
        website_url: this.newUser.websiteURL,
        brands: this.newUser.brands,
        email_hash: CryptoJS.HmacSHA256(this.newUser.email, secret).toString()
      };

      console.log(payload);

      this.$axios.post('/register', payload)
          .then(response => {
            this.success = true;
            this.submitted = true;
          })
          .catch(error => {
            this.success = false;
            this.submitted = true;
            console.error('There was an error creating the user:', error);
          });
    },
    async checkEmail(email) {
      try {
        let response = await this.$axios.request({
          url: '/register/check_email/',
          method: 'POST',
          data: {
            email: email,
          },
        });
        return response.data.status === 'error';
      } catch (error) {
        console.error('Error checking email:', error);
        return false;
      }
    },
    async checkEmailExistence() {
      // Validate email format first
      if (!this.newUser.email.includes('@')) {
        this.emailError = this.$translate('register.error.invalidEmail');
        this.emailExists = true;
        return;
      }

      try {
        const emailExists = await this.checkEmail(this.newUser.email);
        this.emailExists = emailExists;
        this.emailError = emailExists
            ? this.$translate('register.error.emailExists')
            : '';
      } catch (error) {
        console.error('Error checking email existence:', error);
        this.emailError = this.$translate('register.error.emailCheckFailed');
      }
    },
    resetForm() {
      this.step = 1;
      this.submitted = false;
      this.success = false;
      this.newUser = {
        email: "",
        password: "",
        confirmPassword: "",
        group: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        postalCode: "",
        country: "",
        companyName: "",
        websiteURL: "",
        brands: "",
        email_hash: "",
      };
    },
  },
  mounted() {
    this.getAllUserGroups();
  },
};
</script>

<style scoped>

.container {
  max-width: 600px;
}

/* Language Selector Start */

.language-selector {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  background:rgb(238, 238, 238);
  border-radius: 8px;
}

.language-option {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.language-option img {
  transition: box-shadow 0.3s ease;
}



.language-option.active {
  background: rgb(241,111, 36);
  color: white!important;
}

.language-option.active img {
  box-shadow: 0 0 10px #4caf50;
}

.language-option span {
  transition: color 0.3s ease;
}

.language-option:hover span {
  color: #0f0f0f;
}

/* Language Selector End */

/**

select option:checked {
  background-color: #fff;
  color: white;
}

select:focus option {
  background-color: #000;
  color: white;
}

**/

</style>

<template>
  <label class="relative inline-flex items-center cursor-pointer">
    <input
        type="checkbox"
        :checked="localValue"
        @change="onToggle"
        class="sr-only"
    />
    <div class="toggle-bg w-10 h-6 rounded-full"></div>
    <div
        class="toggle-knob absolute w-4 h-4 bg-white rounded-full shadow transition transform duration-200 ease-in-out"
        :class="localValue ? 'translate-x-4 bg-orange-500' : 'translate-x-0'"
    ></div>
  </label>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

// Define a computed property that syncs with the modelValue prop
const localValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});

// Method to toggle the checkbox state
const onToggle = () => {
  localValue.value = !localValue.value;
};
</script>

<style scoped>
.toggle-bg {
  background-color: #e5e7eb; /* Gray background when not checked */
}

input[type="checkbox"]:checked + .toggle-bg {
  background-color: #f16f24; /* Orange background when checked */
}

.toggle-knob {
  transition: transform 0.2s ease;
}
</style>

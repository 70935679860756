<template>
  <transition name="fade">
    <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div class="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg mx-4 transform transition-all duration-300 ease-in-out scale-95 hover:scale-100">
        <h3 class="text-2xl font-semibold text-gray-800 mb-4">{{ title }}</h3>
        <p class="text-gray-700 mb-4">{{ message }}</p>

        <div class="flex justify-between items-center gap-4">
          <button
              @click="confirmAction"
              class="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 transition duration-200"
          >
            {{ actionLabel }}
          </button>
          <button
              @click="closeModal"
              class="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-200"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    actionLabel: {
      type: String,
      default: "Confirm",
    },
  },
  methods: {
    confirmAction() {
      this.$emit("confirm");
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>

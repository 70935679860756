<template>
  <div class="mb-4 flex justify-between items-center gap-4">
    <input
        type="text"
        :placeholder="$translate(placeholder)"
        v-model="searchQuery"
        class="p-2 rounded bg-gradient-white text-black placeholder-gray-600 focus:outline-none focus:ring-2 focus:ring-orange-500"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    placeholder: String
  },
  data() {
    return {
      searchQuery: this.value,
    };
  },
  watch: {
    searchQuery(newQuery) {
      this.$emit('update:value', newQuery);
    }
  },
};
</script>

<style scoped>
/* Add any necessary styles */
</style>

<template>
  <DashboardLayout>
    <div class="p-6 bg-admin-100 shadow-lg rounded-lg max-w-3xl mx-auto mt-10">
      <h2 class="text-2xl font-semibold text-center text-orange-600 mb-6">White Label Settings</h2>

      <!-- Tab Navigation -->
      <div class="flex justify-around mb-6">
        <button
            @click="activeTab = 1"
            :class="{'text-orange-600 border-b-2 border-orange-600': activeTab === 1}"
            class="py-2 px-4 text-gray-600 hover:text-orange-600 font-semibold"
        >
          Brand Info
        </button>
        <button
            @click="activeTab = 2"
            :class="{'text-orange-600 border-b-2 border-orange-600': activeTab === 2}"
            class="py-2 px-4 text-gray-600 hover:text-orange-600 font-semibold"
        >
          Brand Colors
        </button>
        <button
            @click="activeTab = 3"
            :class="{'text-orange-600 border-b-2 border-orange-600': activeTab === 3}"
            class="py-2 px-4 text-gray-600 hover:text-orange-600 font-semibold"
        >
          Extras
        </button>
      </div>

      <!-- Tab Content -->
      <form @submit.prevent="">
        <!-- Brand Info Tab -->
        <div v-if="activeTab === 1">
          <div class="mb-4">
            <input
                type="text"
                v-model="whiteLabelSettings.brandName"
                placeholder="Brand Name"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
            />
          </div>
          <div class="mb-4">
            <input
                type="url"
                v-model="whiteLabelSettings.brandWebsite"
                placeholder="Brand Website URL"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
            />
          </div>
          <div class="mb-4">
            <label class="text-gray-600 font-thin">Logo Upload</label>
            <input
                type="file"
                @change="handleLogoUpload"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <div
                v-if="whiteLabelSettings.logo"
                class="mt-4 flex flex-col items-center justify-center"
            >
              <div class="relative w-40 h-40 border border-gray-300 rounded-lg shadow-md overflow-hidden bg-white">
                <img
                    :src="'https://api-affiliate.web24.me' + whiteLabelSettings.logo"
                    alt="Logo Preview"
                    class="w-full h-full object-contain"
                />
                <button
                    @click="clearLogo"
                    class="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center hover:bg-red-700 focus:outline-none"
                    title="Remove Logo"
                >
                  <span class="material-icons">close</span>
                </button>
              </div>
              <p class="text-sm text-gray-600 mt-2">Logo preview</p>
            </div>
          </div>
          <div class="mb-4">
            <label class="text-gray-600 font-thin">Upload Favicon</label>
            <input
                type="file"
                @change="handleFaviconUpload"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <div
                v-if="whiteLabelSettings.favicon"
                class="mt-4 flex flex-col items-center justify-center"
            >
              <div class="relative p-5 w-24 h-24 border border-gray-300 rounded-full shadow-md overflow-hidden bg-white">
                <img
                    :src="'https://api-affiliate.web24.me' + whiteLabelSettings.favicon"
                    alt="Favicon Preview"
                    class="w-full h-full object-contain"
                />
                <!-- Clear Button -->
                <button
                    @click="clearFavicon"
                    class="absolute top-4 right-3 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center hover:bg-red-700 focus:outline-none"
                    title="Remove Favicon"
                >
                  <span class="material-icons text-xs">close</span>
                </button>
              </div>
              <!-- Preview Caption -->
              <p class="text-sm text-gray-600 mt-2">Favicon preview</p>
            </div>
          </div>

        </div>

        <!-- Brand Colors Tab -->
        <div v-if="activeTab === 2">
          <div class="mb-4">
            <input
                type="color"
                v-model="whiteLabelSettings.primaryColor"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <label class="text-gray-600">Primary Color</label>
          </div>
          <div class="mb-4">
            <input
                type="color"
                v-model="whiteLabelSettings.secondaryColor"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
            <label class="text-gray-600">Secondary Color</label>
          </div>
        </div>

        <!-- Extras Tab -->
        <div v-if="activeTab === 3">
          <div class="mb-4">
            <input
                type="email"
                v-model="whiteLabelSettings.supportEmail"
                placeholder="Support Email"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                required
            />
          </div>
          <div class="mb-4">
    <textarea
        v-model="whiteLabelSettings.customCSS"
        placeholder="Add Custom CSS"
        class="p-2 w-full h-32 rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
    ></textarea>
          </div>
          <div class="mb-4">
            <select
                id="default-usergroup"
                v-model="whiteLabelSettings.defaultUsergroup"
                class="p-3 w-full rounded border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
            >
              <option value="" disabled> Select Default Usergroup Upon Registration(Affiliate)</option>
              <option v-for="group in usergroups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </select>
          </div>

          <div class="mb-4">
            <select
              id="default-client-usergroup"
              v-model="whiteLabelSettings.defaultClientUsergroup"
              class="p-3 w-full rounded border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
            >
              <option value="" disabled> Select Default Client Usergroup</option>
              <option v-for="group in usergroups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </select>
          </div>

          <div class="mb-4">
            <select
                id="default-superadmin-usergroup"
                v-model="whiteLabelSettings.defaultSuperAdminUsergroup"
                class="p-3 w-full rounded border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
            >
              <option value="" disabled> Select Default SuperAdmin Usergroup</option>
              <option v-for="group in usergroups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </select>
          </div>

          <div class="mb-4">
            <input
                type="text"
                v-model="whiteLabelSettings.footerText"
                placeholder="Footer Text"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
        </div>

        <!-- Save Button -->
        <div class="flex justify-center mt-6">
          <button type="submit" class="bg-orange-600 text-white px-6 py-3 rounded-xl hover:bg-orange-800 transition duration-300" @click="saveWhiteLabelSettings">
            Save
          </button>
        </div>
      </form>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout },
  data() {
    return {
      activeTab: 1,
      usergroups: [],
      whiteLabelSettings: {
        brandName: '',
        brandWebsite: '',
        primaryColor: '#ff0000',
        secondaryColor: '#00ff00',
        logo: '',
        favicon: '',
        supportEmail: '',
        customCSS: '',
        footerText: '',
        defaultUsergroup: '',
        defaultClientUsergroup: '',
        defaultSuperAdminUsergroup: ''
      }
    };
  },
  methods: {
    async fetchUsergroups(){
      const response = await this.$axios.request({
        method: 'GET',
        url: '/usergroup/id'
      });

      this.usergroups = response.data.user_groups;



      console.log('Fetching user groups...', response.data.user_groups);
    },
    async fetchSettings() {
      let response = await this.$axios.request({
        method: "GET",
        url: "https://api-affiliate.web24.me/settings/whitelabel",
      });
      console.log(typeof response.data);
      console.log(response.data[0]);

      let settings = response.data[0];

      this.whiteLabelSettings.brandName = settings.brand_name;
      this.whiteLabelSettings.brandWebsite = settings.website_url;
      this.whiteLabelSettings.primaryColor = settings.primary_color;
      this.whiteLabelSettings.secondaryColor = settings.secondary_color;
      this.whiteLabelSettings.supportEmail = settings.support_email;
      this.whiteLabelSettings.customCSS = settings.custom_css;
      this.whiteLabelSettings.footerText = settings.footer_text;
      this.whiteLabelSettings.defaultUsergroup = settings.default_usergroup;
      this.whiteLabelSettings.defaultClientUsergroup = settings.default_client_usergroup;
      this.whiteLabelSettings.defaultSuperAdminUsergroup = settings.default_superadmin_usergroup;
      console.log(this.whiteLabelSettings);
    },
    handleLogoUpload(event) {
      this.whiteLabelSettings.logo = event.target.files[0];
    },
    handleFaviconUpload(event) {
      this.whiteLabelSettings.favicon = event.target.files[0];
    },
    async saveWhiteLabelSettings() {
      console.log(this.whiteLabelSettings);
      const response = await this.$axios.request(
          {
            url: "https://api-affiliate.web24.me/settings/whitelabel",
            method: "PUT",
            data: this.whiteLabelSettings,
          }
      );

      console.log(response.data);

      this.$toast.success('Whitelabel Settings Saved');
    },
    clearLogo() {
      this.whiteLabelSettings.logo = null;
    },
    clearFavicon() {
      this.whiteLabelSettings.favicon = null;
    }
  },
  created() {
    this.fetchSettings();
    this.fetchUsergroups();
  }
};
</script>

<style scoped>
/* Styling for the form and overall layout */
.bg-form-100 {
  background-color: #f7f7f7;
}
</style>
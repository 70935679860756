<template>
  <router-view></router-view>
</template>

<script>
import 'material-design-icons/iconfont/material-icons.css';

export default {
  data() {
    return {
      currentView: 'LoginPage',
      brandName: 'System',
      locales:[],
    };
  },
  components: {
  },
  methods: {
    getConfigs() {
      let configs = this.$config;
      this.brandName = configs.settings.brand_name;
      this.i18n.locale = configs.settings.selected_locale;
    }
  },
  watch: {
    brandName(newBrandName) {
      document.title = newBrandName;
    },
  },
  created() {
    this.getConfigs();
  },
};
</script>

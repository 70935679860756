<template>
  <router-view></router-view>
</template>

<script>
import 'material-design-icons/iconfont/material-icons.css';

export default {
  data() {
    return {
      currentView: 'LoginPage',
      brandName: 'System', // Default value
      locales:[],
    };
  },
  components: {
  },
  methods: {
    showForgotPassword() {
      this.currentView = 'ForgotPasswordPage';
    },
    showLogin() {
      this.currentView = 'LoginPage';
    },
    showRegister() {
      this.currentView = 'RegisterPage';
    },
    showDashboard() {
      this.currentView = 'DashboardPage';
    },
    showResetPassword() {
      this.currentView = 'ResetPasswordPage';
    },
    getConfigs(){
      let configs = this.$config;
      this.brandName = configs.settings.brand_name;
      this.i18n.locale = configs.settings.selected_locale;

    }
  },
  watch: {
    // Watch for changes to brandName and update the title accordingly
    brandName(newBrandName) {
      document.title = newBrandName;
    },
  },
  created() {
    this.getConfigs();
    //this.getWhitelabelSettings(); // Fetch whitelabel settings when the component is created
  },
};
</script>

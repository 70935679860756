<template>
  <div class="flex flex-col bg-gray-800 text-white">
    <SidebarHeader :isCollapsed="isCollapsed" @toggle-sidebar="toggleSidebar" />
    <div class="flex-grow flex flex-col" v-if="!isCollapsed">
      <div class="flex-grow overflow-y-auto">
        <ul class="space-y-3">
          <SidebarItem
              v-for="(item, index) in sidebarResources"
              :key="index"
              :title="this.$translate(`nav.${this.formatTitle(item.title)}.title`)"
              :icon="item.icon"
              :sub-items="item.subItems.map(subItem => ({ ...subItem, name: this.$translate(`nav.${this.formatTitle(item.title)}.${this.formatTitle(subItem.name)}`) }))"
              :isOpen="isOpen[item.title]"
              @toggle="toggleSubMenu(item.title)"
              @menu-selected="changeView"
          />
        </ul>
      </div>
      <SidebarFooter :isCollapsed="isCollapsed" />
    </div>
  </div>
</template>

<script>
import SidebarItem from '@/components/Admin/Sidebar/SidebarItem.vue';
import SidebarHeader from '@/components/Admin/Sidebar/SidebarHeader.vue';
import SidebarFooter from '@/components/Admin/Sidebar/SidebarFooter.vue';
import { ADMIN_RESOURCES, AFFILIATE_RESOURCES, CLIENT_RESOURCES } from "@/module/roles";

export default {
  components: {
    SidebarHeader,
    SidebarItem,
    SidebarFooter,
  },
  props: {
    searchQuery: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isCollapsed: false,
      default_affiliate_usergroup: '',
      default_super_admin_usergroup: '',
      default_client_usergroup: '',
      user_group_id: '',
      isOpen: {
        dashboard: false,
        users: false,
        settings: false,
        affiliates: false,
        media: false,
        commission: false,
      },
      sidebarResources: [],
    };
  },
  methods: {
    formatTitle(title) {
      return title.toLowerCase().replace(/\s+/g, '_');
    },
    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed;
      this.$emit('toggle-sidebar', this.isCollapsed);
    },
    async getDefaultGroups(){

      let config =  this.$config;
      this.default_affiliate_usergroup = this.$defaultUsergroups.affiliate;
      this.default_super_admin_usergroup = this.$defaultUsergroups.superadmin;
      this.default_client_usergroup = this.$defaultUsergroups.client;
      this.user_group_id = await config.group;
      this.setSidebarResources();
    },
    // async getDefaultUsergroups() {
    //   let response = await this.$axios.request({
    //     method: "GET",
    //     url: "/usergroup/default",
    //   });
    //   this.default_affiliate_usergroup = response.data.affiliate_usergroup;
    //   this.default_super_admin_usergroup = response.data.super_admin_usergroup;
    //   this.default_client_usergroup = response.data.client_usergroup;
    //
    //   this.user_group_id = response.data.user_group_id;
    //
    //
    //
    //
    //
    //   this.setSidebarResources();
    // },
    setSidebarResources() {
      // Dynamically assign resources based on user group
      if (this.default_super_admin_usergroup === this.user_group_id) {
        this.sidebarResources = [...ADMIN_RESOURCES];
      }
      if (this.default_affiliate_usergroup === this.user_group_id) {
        this.sidebarResources = [...AFFILIATE_RESOURCES];
      }
      if (this.default_client_usergroup === this.user_group_id) {
        this.sidebarResources = [...CLIENT_RESOURCES];
      }
    },
    toggleSubMenu(menu) {
      for (let key in this.isOpen) {
        if (key !== menu) {
          this.isOpen[key] = false;
        }
      }
      this.isOpen[menu] = !this.isOpen[menu];
    },
    changeView(view) {
      this.$emit('menu-selected', view);
    },
  },
  created() {
    //this.getDefaultUsergroups();
   this.getDefaultGroups();
  }
};
</script>

<style scoped>
/* Add any additional styling for the sidebar here */
</style>
<template>
  <DashboardLayout>
    <div class="flex flex-col">
      <div class="flex-1 flex flex-col">
        <div class="flex-1 overflow-y-auto">
          <div class="container max-w-3xl mx-auto p-4 bg-admin-100 text-black rounded-lg shadow-md mt-28">
            <h2 class="text-2xl text-center font-bold text-orange-500 mb-8">{{ $translate('media.media_library.title') }}</h2>
            <div class="mb-4 flex justify-between items-center gap-4">
              <input
                  type="text"
                  v-model="searchQuery"
                  :placeholder= "$translate('search.by_banner_name')"
                  class="p-2 rounded bg-white text-black placeholder-gray-600 focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <div
                  v-for="(banner) in paginatedBanners"
                  :key="banner.id"
                  class="bg-gray-200 rounded-lg overflow-hidden shadow-md "
              >
                <div class="w-full h-40 bg-gray-100 flex items-center justify-center">
                  <img
                      :src="'https://api-affiliate.web24.me' + banner.imageUrl"
                      alt="Banner"
                      class="max-w-full max-h-full object-contain"
                  />
                </div>
                <div class="p-4">
                  <h3 class="font-semibold text-lg">{{ banner.name }}</h3>
                  <p class="text-sm text-gray-600">{{ banner.description }}</p>
                  <div class="flex justify-between mt-4 gap-6">
                    <button
                        v-show="role === 'admin' || role === 'client'"
                        @click="editBanner(banner)"
                        class="w-full material-icons flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
                    >
                      edit
                    </button>
                    <button
                        v-show="!disabled"
                        @click="confirmToDelete(banner.id)"
                        class="w-full material-icons flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-800 focus:outline-none mt-4"
                    >
                      delete
                    </button>
                      <ButtonComponent
                          v-show="role === 'affiliate'"
                          @click="generateBannerCode(banner.id)"
                          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
                      >
                        Generate Code
                      </ButtonComponent>
                  </div>
                </div>
              </div>
            </div>
            <PaginationComponent
                :currentPage="currentPage"
                :totalPages="totalPages"
                @update:currentPage="currentPage = $event"
            />

          </div>
        </div>
      </div>

      <transition name="fade">
        <div
            v-if="isModalOpen"
            class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <div
              class="bg-white rounded-lg shadow-lg z-10 p-6 max-h-screen max-w-screen-md w-full overflow-y-auto"
          >
            <h2 class="text-2xl font-bold text-orange-500 mb-4">
              {{ $translate('media.media_library.edit_banner') }}
            </h2>
            <form @submit.prevent="updateBanner(newBanner)">
              <div class="mb-4" v-show="admin">
                <label for="client_id" class="block text-sm font-medium text-gray-700">
                  {{ $translate('media.media_library.client_id') }}
                </label>
                <input
                    type="text"
                    v-model="newBanner.client_id"
                    :placeholder="$translate('media.media_library.client_id')"
                    class="p-2 w-full rounded shadow-xl bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                    :disabled="disabled"
                />
              </div>
              <div class="mb-4">
                <label for="name" class="block text-sm font-medium text-gray-700">
                  {{ $translate('media.media_library.banner_name') }}
                </label>
                <input
                    type="text"
                    v-model="newBanner.name"
                    :placeholder="$translate('media.media_library.banner_name')"
                    class="p-2 w-full rounded shadow-xl bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                    :disabled="disabled"
                />
              </div>

              <div class="mb-4">
                <label for="brand_name" class="block text-sm font-medium text-gray-700">
                  {{ $translate('media.media_library.brand_name') }}
                </label>
                <input
                    type="text"
                    v-model="this.brands[newBanner.brand_id].name"
                    :placeholder="$translate('media.media_library.banner_name')"
                    class="p-2 w-full rounded shadow-xl bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                    :disabled="true"
                />
              </div>

              <div class="mb-4">
                <label for="product_name" class="block text-sm font-medium text-gray-700">
                  {{ $translate('media.media_library.product_name') }}
                </label>
                <input
                    type="text"
                    v-model="this.products[newBanner.product_id].name"
                    :placeholder="$translate('media.media_library.banner_name')"
                    class="p-2 w-full rounded shadow-xl bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                    :disabled="true"
                />
              </div>

              <div class="mb-4">
                <label for="source_name" class="block text-sm font-medium text-gray-700">
                  {{ $translate('media.media_library.source_name') }}
                </label>
                <input
                    type="text"
                    v-model="this.sources[newBanner.source_id].name"
                    :placeholder="$translate('media.media_library.banner_name')"
                    class="p-2 w-full rounded shadow-xl bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                    :disabled="true"
                />
              </div>

              <div class="mb-4">
                <input
                    type="file"
                    v-show="!newBanner.imageUrl"
                    @change="handleFileChange"
                    :placeholder="$translate('media.media_library.banner_img')"
                    class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                    :disabled="disabled"
                />
                <div v-if="isValidImageUrl(newBanner.imageUrl)" class="mt-4">
                  <label class="block text-sm font-medium text-gray-700">
                    {{ $translate('media.media_library.banner_preview') }}
                  </label>
                  <div class="w-full h-32 border rounded bg-form-100 flex items-center justify-center">
                  <img
                      :src="'https://api-affiliate.web24.me' + newBanner.imageUrl"
                      alt="Banner Preview"
                      class="max-w-full max-h-full object-contain"
                  />
                    </div>
                </div>
              </div>

              <div class="mb-4">
                <label for="description" class="block text-sm font-medium text-gray-700">
                  {{ $translate('media.media_library.banner_description') }}
                </label>
                <textarea
                    v-model="newBanner.description"
                    :placeholder="$translate('media.media_library.banner_description')"
                    class="p-2 w-full rounded bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                    :disabled="!disabled"
                ></textarea>
              </div>

              <div class="mb-4" v-if="!disabled">
                <label for="assigned_affiliates" class="block text-sm font-medium text-gray-700">
                  {{ $translate('media.media_library.assigned_affiliates') }}
                </label>
                <select
                    id="assigned_affiliates"
                    v-model="newBanner.assignedAffiliates"
                    multiple
                    class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-base focus:ring-orange-500 focus:border-orange-500"
                >
                  <option
                      v-for="affiliate in contractedAffiliates"
                      :key="affiliate.id"
                      :value="affiliate.id"
                  >
                    {{ affiliate.first_name + ' ' + affiliate.last_name }}
                  </option>
                </select>
              </div>

              <div class="flex justify-center mt-6">
                <ButtonComponent
                    type="submit"
                    class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800"
                >
                  {{ $translate('save') }}
                </ButtonComponent>
              </div>
            </form>
            <ButtonComponent @click="closeModal" class="mt-4 text-red-500">
              {{ $translate('cancel') }}
            </ButtonComponent>
          </div>
        </div>
      </transition>

        <transition name="fade">
          <div
              v-show="isGeneratingCode"
              class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <div
                class="bg-white rounded-lg shadow-lg z-10 p-6 max-h-screen max-w-screen-md w-full overflow-y-auto"
            >
              <h2 class="text-2xl font-bold text-orange-500 mb-4">
                {{ $translate('media.media_library.generate_code') }}
              </h2>
              <div class="mb-4">
                <label for="code" class="block text-sm font-medium text-gray-700">
                  {{ $translate('media.media_library.generated_code') }}
                </label>
                <div class="flex items-center">
                  <input
                      type="text"
                      v-model="generatedCode"
                      :placeholder="$translate('media.media_library.generated_code')"
                      class="p-2 w-full h-24 rounded-l shadow-xl bg-gray-200 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      :disabled="true"
                  />

                </div>
              </div>
              <div class="m-2">
                <button class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800"
                @click="copyCode()">
                  {{ $translate('media.media_library.copy_code') }}
                </button>
              </div>
              <div class="flex justify-center mt-6">

                <ButtonComponent
                    @click="closeCodeModal"
                    class="bg-orange-600 text-white px-4 py-2 rounded hover:bg-orange-800"
                >
                  {{ $translate('close') }}
                </ButtonComponent>
              </div>
            </div>
          </div>
        </transition>


      <ConfirmationModalComponent
          :isOpen="isConfirmationModalOpen"
          :title="$translate('delete')"
          :message="$translate('are_you_sure')"
          :actionLabel="$translate('delete')"
          @confirm="deleteBannerModal"
          @close="closeDeleteModal"
      />
    </div>
  </DashboardLayout>
</template>

<script>
import ButtonComponent from "@/components/Fields/ButtonComponent.vue";
import DashboardLayout from "@/views/DashboardLayout.vue";
import ConfirmationModalComponent from "@/components/Fields/ConfirmationModalComponent.vue";
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";

export default {
  components: {PaginationComponent, DashboardLayout, ButtonComponent, ConfirmationModalComponent },
  data() {
    return {
      isModalOpen: false,
      recordsPerPage: 6,
      currentPage: 1,
      isGeneratingCode: false,
      isConfirmationModalOpen: false,
      bannerIdToDelete: null,
      generatedCode: '',
      disabled: false,
      role: 'affiliate',
      admin: false,
      activeAssigned: [],
      banners: [],
      newBanner: { name: '', imageUrl: '', description: '', assignedAffiliates: [], client_id : '', brand_id: '', product_id: '', source_id: '', code: '' },
      searchQuery: '',
      contractedAffiliates: []
    };
  },
  computed: {
    filteredBanners() {
      return this.banners.filter((banner) =>
          banner.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    totalPages() {
      if(this.filteredBanners.length === 0) return 1;
      return Math.ceil(this.filteredBanners.length / this.recordsPerPage);
    },
    paginatedBanners() {
      const start = (this.currentPage - 1) * this.recordsPerPage;
      return this.filteredBanners.slice(start, start + this.recordsPerPage);
    }
  },
  methods: {
    async generateBannerCode(id) {
      if (this.isGeneratingCode) return;
      this.isGeneratingCode = true;
      try {
        let response = await this.$axios.request({
          method: "POST",
          url: "/banner_generator",
          data: {
            banner_id: id
          },
        });

        let trackingId = response.data.banner.redirect_id;

        response = await this.$axios.request({
          method: "GET",
          url: "/media_library/id/" + id,
        });

        let banner = response.data.media[0];

        this.bannerURL = 'https://affiliate.web24.me/redirect/' + trackingId;
        this.imageUrl = 'https://api-affiliate.web24.me' + banner.src_path;

        this.generatedCode = `<a href="${this.bannerURL}" target="_blank"><img src="${this.imageUrl}" alt="${banner.name}" /></a>`;
      } catch (error) {
        console.error("Failed to generate banner code:", error);
      }
    },
    confirmToDelete(id) {
      this.bannerIdToDelete = id;
      this.isConfirmationModalOpen = true;
    },
    async deleteBannerModal() {
      await this.deleteBanner(this.bannerIdToDelete);
      this.$toast.success("Banner deleted successfully");
      this.closeDeleteModal();
    },
    closeDeleteModal() {
      this.isConfirmationModalOpen = false;
      this.bannerIdToDelete = null;
    },
    copyCode() {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        // Use the clipboard API if available
        navigator.clipboard.writeText(this.generatedCode)
            .then(() => {
              this.$toast.success("HTML Code Copied to Clipboard");
            })
            .catch((err) => {
              console.error("Failed to copy text: ", err);
              this.$toast.error("Failed to copy code. Please try again.");
            });
      } else {
        // Fallback for older browsers
        const textarea = document.createElement("textarea");
        textarea.value = this.generatedCode;
        textarea.style.position = "fixed"; // Prevent scrolling to the bottom
        textarea.style.opacity = "0";
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand("copy");
          this.$toast.success("HTML Code Copied to Clipboard");
        } catch (err) {
          console.error("Fallback copy failed: ", err);
          this.$toast.error("Failed to copy code. Please try again.");
        }
        document.body.removeChild(textarea);
      }
    },
    openModal() {
      this.isModalOpen = true;
    },
    editBanner(banner) {
      if(this.disabled === true) {
        this.newBanner = {...banner};
      }
      else {
        this.newBanner = { ...banner,
          assignedAffiliates: this.activeAssigned[banner.id]?.map(affiliate => affiliate.affiliate_id) || []
        };
        this.initialAssignedAffiliates = [...this.newBanner.assignedAffiliates];

        if(this.admin === true){
          this.newBanner.client_id = banner.client_id;
        }
      }
      console.log('Edit banner:', this.newBanner);
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.resetForm();
    },
    closeCodeModal(){
      this.isGeneratingCode = false;
    },
    async fetchBanners() {
      try {
        const response = await this.$axios.request(
            {
              url: '/media_library/id',
              method: 'get'
            }
        );
        this.banners = response.data.media.map((banner) => ({
          ...banner,
          imageUrl: banner.src_path,
          client_id: banner.client_id
        }));
        this.contractedAffiliates = response.data.contracted_affiliates;
        this.activeAssigned = response.data.active_assigned;
        this.disabled = response.data.disabled;
        this.sources = response.data.sources;
        this.role = response.data.role;
        this.products = response.data.products;
        this.brands = response.data.brands;
        this.admin = response.data.admin;
      } catch (error) {
        console.error("Failed to fetch banners:", error);
      }
    },
    async updateBanner(banner) {
      const removedAffiliates = this.initialAssignedAffiliates.filter(
          (affiliate) => !banner.assignedAffiliates.includes(affiliate)
      );
      try {
        const response = await this.$axios.request({
          url: `/media_library/id`,
          method: 'put',
          data: {
            id: banner.id,
            name: banner.name,
            description: banner.description,
            src_path: banner.imageUrl,
            assigned_affiliates: banner.assignedAffiliates,
            removed_affiliates: removedAffiliates
          }
        });

        let updatedMedia = response.data.media;
        console.log('this.banners');
        console.log(this.banners);
        console.log('updatedMedia');
        console.log(updatedMedia);

        this.banners = this.banners.map((banner) => {
          if(banner.id === updatedMedia.id) {
            return { ...updatedMedia, imageUrl: updatedMedia.src_path };
          }
          return banner;
        });

        if(response.data.status === 'success') this.$toast.success(this.$translate('media.media_library.messages.update_success'));
        else this.$toast.error(this.$translate('error_updating'));

        //close the edit modal
        this.closeModal();


      } catch (error) {
        console.error("Failed to update banner:", error);
      }
    },
    clearImage() {
      this.newBanner.imageUrl = '';
    },
    async deleteBanner(id) {
      try {
        await this.$axios.request({
          url: `/media_library/id/${id}`,
          method: 'delete'
        });
        this.banners = this.banners.filter((banner) => banner.id !== id);
      } catch (error) {
        console.error("Failed to delete banner:", error);
      }
    },
    resetForm() {
      this.newBanner = { name: '', imageUrl: '', description: '', assignedAffiliates: [] };
    },
    isValidImageUrl(url) {
      return url && /\.(jpg|jpeg|png|gif)$/.test(url);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.newBanner.imageUrl = URL.createObjectURL(file);
      }
    }
  },
  created() {
    this.fetchBanners();
  },

};
</script>

<style scoped>
.container {
  max-width: 90%;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

img {
  object-fit: cover;
}
</style>

<template>
  <DashboardLayout>
    <div class="flex flex-col h-screen bg-gray-100">
      <!-- Main content area -->
      <div class="flex-1 flex flex-col p-8">
        <!-- Notification Counters -->
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
          <div class="bg-orange-500 text-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow">
            <p class="text-lg font-semibold">Total Messages</p>
            <p class="text-2xl">{{ messages.length }}</p>
          </div>
          <div class="bg-orange-500 text-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow">
            <p class="text-lg font-semibold">Unread Messages</p>
            <p class="text-2xl">{{ unreadCount }}</p>
          </div>
          <div class="bg-orange-500 text-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow">
            <p class="text-lg font-semibold">Important Messages</p>
            <p class="text-2xl">{{ importantCount }}</p>
          </div>
        </div>

        <!-- Inbox -->
        <div class="bg-white rounded-lg shadow-lg p-6 mb-8">
          <div class="flex items-center justify-between mb-6">
            <h2 class="text-3xl font-bold text-orange-600">Inbox</h2>
            <button
                class="bg-orange-600 text-white px-6 py-3 rounded-lg hover:bg-orange-700 transition-colors"
                @click="openMessageModal"
            >
              Create New Message
            </button>
          </div>

          <!-- Message List -->
          <div class="overflow-x-auto">
            <div class="min-w-full bg-gray-100 rounded-lg">
              <div class="flex justify-between bg-orange-500 text-white p-4 rounded-t-lg">
                <div class="w-1/4 text-center">Subject</div>
                <div class="w-1/4 text-center">Message</div>
                <div class="w-1/4 text-center">Date</div>
                <div class="w-1/6 text-center">Status</div>
                <div class="w-1/6 text-center">Actions</div>
              </div>
              <div v-for="message in filteredMessages" :key="message.id" class="flex items-center border-b border-gray-300 hover:bg-gray-50 transition-colors">
                <div class="w-1/4 text-center py-4 px-6">{{ message.subject }}</div>
                <div class="w-1/4 text-center py-4 px-6">{{ message.text }}</div>
                <div class="w-1/4 text-center py-4 px-6">{{ message.date }}</div>
                <div class="w-1/6 text-center py-4 px-6">{{ message.isRead ? 'Read' : 'Unread' }}</div>
                <div class="w-1/6 text-center py-4 px-6">
                  <div class="flex justify-center space-x-2">
                    <button
                        @click="viewMessage(message)"
                        class="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors"
                    >
                      <span class="material-icons">visibility</span>
                    </button>
                    <button
                        @click="deleteMessage(message.id)"
                        class="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors"
                    >
                      <span class="material-icons">delete</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Message Modal -->
      <transition name="fade">
        <div v-if="isMessageModalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div class="bg-white rounded-lg shadow-lg z-10 p-8 max-w-lg w-full transform transition-all duration-300 ease-in-out scale-95 hover:scale-100">
            <h2 class="text-2xl font-bold text-orange-600 mb-4">{{ isEditMode ? 'Edit Message' : 'Create New Message' }}</h2>
            <form @submit.prevent="sendMessage">
              <div class="mb-6">
                <input
                    type="text"
                    v-model="newMessage.subject"
                    placeholder="Message Subject"
                    class="p-4 w-full rounded-lg bg-gray-200 text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>
              <div class="mb-6">
                <input
                    type="text"
                    v-model="newMessage.recipient"
                    placeholder="Message Recipient"
                    class="p-4 w-full rounded-lg bg-gray-200 text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
              </div>
              <div class="mb-6">
                <select
                    v-model="newMessage.priority"
                    class="p-4 w-full rounded-lg bg-gray-200 text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
                >
                  <option value="default" disabled>Select Priority</option>
                  <option value="1">Low</option>
                  <option value="2">Normal</option>
                  <option value="3">Important</option>
                  <option value="4">Very Important</option>
                </select>
              </div>
              <div class="mb-6">
                <textarea
                    v-model="newMessage.text"
                    placeholder="Message Text"
                    rows="4"
                    class="p-4 w-full rounded-lg bg-gray-200 text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
                ></textarea>
              </div>
              <div class="flex justify-center gap-4 mb-6">
                <button
                    type="submit"
                    class="bg-orange-600 text-white px-6 py-3 rounded-lg hover:bg-orange-700 transition-colors"
                >
                  {{ isEditMode ? 'Update Message' : 'Send Message' }}
                </button>
                <button
                    @click="closeMessageModal"
                    class="bg-orange-600 text-white px-6 py-3 rounded-lg hover:bg-orange-700 transition-colors"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/views/DashboardLayout.vue";

export default {
  components: { DashboardLayout },
  data() {
    return {
      messages: [
        {
          id: 1,
          subject: 'Message 1',
          text: 'This is the first message.',
          date: '2024-11-12',
          isRead: false,
          isImportant: true
        },
        {
          id: 2,
          subject: 'Message 2',
          text: 'This is the second message.',
          date: '2024-11-11',
          isRead: true,
          isImportant: false
        },
        {
          id: 3,
          subject: 'Message 3',
          text: 'This is the third message.',
          date: '2024-11-10',
          isRead: false,
          isImportant: false
        },
      ],
      filteredMessages: [],
      isMessageModalOpen: false,
      isEditMode: false,
      newMessage: {
        id: null,
        subject: '',
        recipient: '',
        priority: 'default',
        text: '',
      },
    };
  },
  created() {
    this.filterMessages('all');
  },
  computed: {
    unreadCount() {
      return this.messages.filter(message => !message.isRead).length;
    },
    importantCount() {
      return this.messages.filter(message => message.isImportant).length;
    },
  },
  methods: {
    filterMessages(status) {
      if (status === 'all') {
        this.filteredMessages = this.messages;
      } else if (status === 'unread') {
        this.filteredMessages = this.messages.filter(message => !message.isRead);
      } else if (status === 'read') {
        this.filteredMessages = this.messages.filter(message => message.isRead);
      }
    },
    viewMessage(message) {
      console.log('Viewing message:', message);
      message.isRead = true;
      this.$router.push({name: 'message', params: {id: message.id}});
    },
    openMessageModal() {
      this.isMessageModalOpen = true;
      this.isEditMode = false;
      this.newMessage = {id: null, subject: '', priority: 'default', text: ''}; // Reset form with default priority
    },
    closeMessageModal() {
      this.isMessageModalOpen = false;
      this.newMessage = {id: null, subject: '', priority: 'default', text: ''}; // Reset form with default priority
    },
    sendMessage() {
      if (this.isEditMode) {
        this.updateMessage();
      } else {
        this.addMessage();
      }
      this.closeMessageModal();
    },
    addMessage() {
      this.messages.push({
        id: this.messages.length + 1,
        subject: this.newMessage.subject,
        text: this.newMessage.text,
        date: new Date().toLocaleDateString(),
        isRead: false,
        isImportant: this.newMessage.priority === 'true',
      });
    },
    updateMessage() {
      const messageIndex = this.messages.findIndex(message => message.id === this.newMessage.id);
      if (messageIndex !== -1) {
        this.messages[messageIndex] = {...this.newMessage, isImportant: this.newMessage.priority === 'true'};
      }
    },
    deleteMessage(id) {
      this.messages = this.messages.filter(message => message.id !== id);
    },
  },
};
</script>

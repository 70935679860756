<template>
  <DashboardLayout>
    <div class="flex flex-col h-screen">
      <!-- Main Content -->
      <div class="flex-1 flex flex-col">
        <div class="flex-1 overflow-y-auto">
          <div class="container max-w-3xl mx-auto p-4 bg-admin-100 text-black overflow-y-auto rounded-lg shadow-md">
            <div class="flex items-center justify-between">
              <h2 class="text-2xl font-bold mx-auto mb-4 text-orange-500">
                {{ $translate('sources.manage_sources.title') }}
              </h2>
            </div>
            <SearchComponent
                :value="searchQuery"
                @update:value="searchQuery = $event"
                v-model="searchQuery"
                :placeholder="$translate('placeholders.search_sources')"
            />
            <div class="flex flex-col bg-gray-200 rounded-lg">
              <div class="flex justify-between bg-orange-500 text-white p-3 rounded-t-lg">
                <div class="py-3 px-4 font-semibold flex-1">
                  {{ $translate('sources.manage_sources.name') }}
                </div>
                <div class="py-3 px-4 font-semibold flex-1">
                  {{ $translate('client') }}
                </div>
                <div class="py-3 px-4 font-semibold flex-1">
                  {{ $translate('sources.manage_sources.url') }}
                </div>
                <div class="py-3 px-4 font-semibold flex-none">
                  {{ $translate('action') }}
                </div>
              </div>
              <div v-for="source in paginatedSources" :key="source.id" class="flex border-b border-gray-400 hover:bg-gray-300 transition-colors duration-200">
                <div class="py-3 px-4 flex-1">{{ source.name }}</div>
                <div class="py-3 px-4 flex-1">{{ affiliates[source.user_id] }}</div>
                <div class="py-3 px-4 flex-1">{{ source.url }}</div>
                <div class="py-3 px-4 flex-none">
                  <div class="flex space-x-2">
                    <!-- Edit Button -->
                    <button
                        @click="editSource(source)"
                        class="flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none"
                    >
                      <span class="material-icons">edit</span>
                    </button>
                    <!-- Delete Button -->
                    <button
                        @click="confirmDelete(source)"
                        class="flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none"
                    >
                      <span class="material-icons">delete</span>
                    </button>
                  </div>
                </div>

              </div>
              <PaginationComponent
                  :currentPage="currentPage"
                  :totalPages="totalPages"
                  @update:currentPage="currentPage = $event"
                  class="mb-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for editing sources -->
    <transition name="fade">
      <div v-if="isModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
          <h2 class="text-2xl text-orange-500 font-bold mb-4 text-center">{{ $translate('sources.manage_sources.edit_source')}}</h2>
          <div class="mb-4">
            <input
                type="text"
                v-model="newSource.name"
                :placeholder="$translate('placeholders.source_name')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div class="mb-4">
            <input
                type="text"
                v-model="newSource.url"
                :placeholder="$translate('placeholders.source_url')"
                class="p-2 w-full rounded bg-form-100 text-black placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
          <div class="flex justify-center gap-4 mt-6">
            <button
                type="submit"
                @click="updateSource"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none mt-4"
            >
              {{$translate('update')}}
            </button>
            <button
                @click="closeModal"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none mt-4"
            >
              {{$translate('cancel')}}
            </button>
          </div>
        </div>
      </div>
    </transition>

      <!-- Modal for Deletion Confirmation -->
      <ConfirmationModalComponent
          :title="$translate('sources.manage_sources.modal.delete.title')"
          :message="$translate('sources.manage_sources.modal.delete.message')"
          :is-open="isDeleteModalOpen"
          @close="closeDeleteModal"
          @confirm="deleteSource"
          action-label="Delete"
      />

  </DashboardLayout>
</template>


<script>
import DashboardLayout from "@/views/DashboardLayout.vue";
import ConfirmationModalComponent from "@/components/Fields/ConfirmationModalComponent.vue";
import PaginationComponent from "@/components/Fields/PaginationComponent.vue";
import SearchComponent from "@/components/Fields/SearchComponent.vue";

export default {
  components: {SearchComponent, PaginationComponent, ConfirmationModalComponent, DashboardLayout },
  data() {
    return {
      sources: [],
      currentPage: 1,
      searchQuery : "",
      recordsPerPage: 5,
      affiliates: [],
      isModalOpen: false,
      isDeleteModalOpen: false,
      selectedSource: null,
      newSource: {
        id: null,
        name: "",
        url: "",
      },
    };
  },
  methods: {
    editSource(source) {
      this.isModalOpen = true;
      this.newSource = { ...source };
    },
    confirmDelete(source) {
      this.isDeleteModalOpen = true;
      this.selectedSource = source;
    },
    async updateSource() {
      try {
        await this.$axios.request({
          url: `/source/id/${this.newSource.id}`,
          method: "PUT",
          data: this.newSource,
        });

        const index = this.sources.findIndex((s) => s.id === this.newSource.id);
        this.sources[index] = { ...this.newSource };

        this.$toast.success(
            this.$translate("sources.manage_sources.message.update_success")
        );
      } catch (error) {
        console.error(error);
        this.$toast.error(
            this.$translate("sources.manage_sources.message.update_error")
        );
      } finally {
        this.closeModal();
      }
    },
    async deleteSource() {
      if (!this.selectedSource) return;

      try {
        await this.$axios.request({
          url: `/source/id/${this.selectedSource.id}`,
          method: "DELETE",
        });

        this.sources = this.sources.filter(
            (s) => s.id !== this.selectedSource.id
        );

        this.$toast.success(
            this.$translate("sources.manage_sources.message.delete_success")
        );
      } catch (error) {
        console.error(error);
        this.$toast.error(
            this.$translate("sources.manage_sources.message.delete_error")
        );
      } finally {
        this.closeDeleteModal();
      }
    },
    closeDeleteModal() {
      this.isDeleteModalOpen = false;
      this.selectedSource = null;
    },
    closeModal() {
      this.isModalOpen = false;
      this.resetForm();
    },
    resetForm() {
      this.newSource = {
        id: null,
        name: "",
        url: "",
      };
    },
    async fetchSources() {
      try {
        const response = await this.$axios.request({
          url: "/source/id",
          method: "GET",
        });


        this.sources = response.data.sources;
        this.affiliates = response.data.users;

      } catch (error) {
        console.error("Error fetching sources:", error);
      }
    },
  },
  mounted() {
    this.fetchSources();
  },
  computed: {
    totalPages() {
      if(this.filteredSources.length === 0) return 1;
      return Math.ceil(this.filteredSources.length / this.recordsPerPage);
    },
    paginatedSources(){
      let  start = (this.currentPage - 1) * this.recordsPerPage;
      let  end = start + this.recordsPerPage;

      return this.filteredSources.slice(start, end);
    },
    filteredSources(){
      return this.sources.filter(source => {
        return source.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    }
  }
};
</script>
